import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { VAULT_PERMISSION } from "../../../common/constants";
import PageTitle from "../../components/page-title";
import RestrictedPage from "../../components/restricted-page";
import Text from "../../components/text";
import VerticalList from "../../components/vertical-list";
import VideoCard from "../../components/video-card";
import { getCollectionTags } from "../../utils/tags";
import styles from "./styles.module.css";
import TagCard from "./tag-card";
import Screen from "../../components/screen";

const VaultTopic = () => {
  const {
    videos: vaultVideos,
    topics,
    tags: vaultTags,
  } = useSelector((state) => state.vault);
  const videosInProgress = useSelector(
    (state) => state.videosInProgress.videos
  );
  const [topic, setTopic] = useState();
  const [selectedTags, setSelectedTags] = useState({});
  const { id: _topicId } = useParams();

  const tagVideos = useMemo(() => {
    return getCollectionTags(vaultVideos, vaultTags);
  }, [vaultTags, vaultVideos]);

  useEffect(() => {
    if (_topicId && topics?.length) {
      setTopic(topics.find((t) => t.id === _topicId));
    }
  }, [_topicId, topics]);

  const videos = useMemo(() => {
    if (!tagVideos?.length || !topic?.tags?.length) return [];
    return tagVideos.filter((v) =>
      v.tags?.some((t) => topic.tags.includes(t.id))
    );
  }, [tagVideos, topic?.tags]);

  const tags = useMemo(() => {
    return vaultTags.reduce((acc, current) => {
      if (topic?.tags?.includes(current.id)) {
        acc[current.id] = current;
      }
      return acc;
    }, {});
  }, [topic?.tags, vaultTags]);

  // const {
  //   shortVideos,
  //   fullInterviewVideos
  // } = useMemo(() => {
  //   return getVideosInFixedCategories(videos);
  // }, [videos]);

  const highlightVideosIds = useMemo(() => {
    const selectedTagsArray = Object.keys(selectedTags);
    return vaultVideos
      .filter((v) => {
        return selectedTagsArray.some((tagId) => v.tags?.includes(tagId));
      })
      .map((v) => v.id);
  }, [selectedTags, vaultVideos]);

  if (!topic) return null;

  const toggleTag = (tagId) => {
    setSelectedTags((prev) => {
      if (prev[tagId]) delete prev[tagId];
      else prev[tagId] = true;
      return { ...prev };
    });
  };

  return (
    <RestrictedPage restrictedTitle="The Vault" allowedPlans={VAULT_PERMISSION}>
      <Screen>
        <PageTitle title={topic.title} />
        <div className={styles.headerContainer}>
          <div>
            <Text variant="h3">Topic Overview</Text>
            <div className={styles.headerSectionContainer}>
              <Text variant="description">{topic.description}</Text>
            </div>
          </div>
          <div>
            <Text variant="h3">Tags</Text>
            <div className={styles.tagsContainer}>
              {(topic.tags || []).map((tagId) => {
                const isSelected = selectedTags[tagId];
                return (
                  <TagCard
                    key={`tag-${tagId}`}
                    tag={tags[tagId]}
                    isSelected={isSelected}
                    onClick={() => toggleTag(tagId)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className={styles.sectionContainer}>
        <VerticalList
          key="short"
          title="Shorts"
          data={shortVideos.map(video => ({ video, videosInProgress, highlightVideosIds }))}
          Component={VideoCard}
        />
      </div> */}
        <div className={styles.sectionContainer}>
          <VerticalList
            id="video"
            title="Videos"
            data={videos.map((video) => ({
              video,
              videosInProgress,
              highlightVideosIds,
            }))}
            Component={VideoCard}
            videosFromSection={videos}
          />
        </div>
        {/* <div className={styles.sectionContainer}>
        <VerticalList
          id="full-interview"
          title="Full Interviews"
          data={fullInterviewVideos.map(video => ({ video, videosInProgress, highlightVideosIds }))}
          Component={VideoCard}
        />
      </div> */}
      </Screen>
    </RestrictedPage>
  );
};

export default React.memo(VaultTopic);
