import cn from 'classnames';
import React from "react";
import DP from 'react-dropdown';
import Button from '../button';
import Text from '../text';
import styles from './styles.module.css';

const Dropdown = ({
  className,
  controlClassName,
  menuClassName,
  label,
  labelClassName,
  onClear,
  disabled,
  ...props
}) => {
  return (
    <div className={cn(styles.container, className)}>
      {label ? <Text className={cn(styles.label, labelClassName)}>{label}</Text> : null}
      <div className={styles.contentContainer}>
        <DP
          controlClassName={cn(styles.dropdownContainer, controlClassName)}
          menuClassName={cn(styles.menuClassName, menuClassName)}
          className={styles.containerClassName}
          disabled={disabled}
          {...props}
        />
        {onClear ? <Button onClick={disabled ? null : onClear}>X</Button> : null}
      </div>
    </div>
  )
};

export default React.memo(Dropdown);
