import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Title from "../.title";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";
import databaseGIF from "../../assets/images/database_gif_wide.gif";
import vaultGIF from "../../assets/images/vault_gif_wide.gif";
import reviewGIF from "../../assets/images/review_gif_wide.gif";
import reviewGIFcover from "../../assets/images/review_gif_cover.gif";

const ProductCard = ({ titleContent, line1, line2, extraContent, variant }) => {
  const history = useHistory();

  const variantStyle = useMemo(() => {
    if (variant === "1") return styles.one;
    if (variant === "2") return styles.two;
    if (variant === "3") return styles.three;
  }, [variant]);

  return (
    <div className={styles.stackedCardContainer}>
      <div className={variantStyle} />
      <Title style={{ marginBottom: "10px" }}>{titleContent}</Title>
      <Text className={styles.contentContainer}>{line1}</Text>
      <Text className={styles.contentContainer}>{line2}</Text>

      {extraContent ? (
        <div className={styles.extraContentContainer}>{extraContent}</div>
      ) : null}

      <div className={styles.imgContainer}>
        <img
          src={
            variant === "1"
              ? databaseGIF
              : variant === "2"
              ? vaultGIF
              : variant === "3"
              ? reviewGIF
              : null
          }
          alt={
            variant === "1"
              ? "Database GIF"
              : variant === "2"
              ? "The Vault GIF"
              : variant === "3"
              ? "Film Review GIF"
              : null
          }
          onClick={() =>
            variant === "1"
              ? history.push(BASE_ROUTES.database)
              : variant === "2"
              ? history.push(BASE_ROUTES.vault)
              : variant === "3"
              ? history.push(BASE_ROUTES.review)
              : null
          }
        />
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
