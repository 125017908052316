import React, { useEffect, useRef, useState } from "react";
import { getSchool } from "../../../common/services/firebase/database";
import CloseIcon from "../../assets/icons/filter-arrow-left-white.svg";
import OpenIcon from "../../assets/icons/filter-arrow-right-white.svg";
import HandleIcon from "../../assets/icons/handle.svg";
import FlatUpArrow from "../../assets/icons/flat_up_arrow.svg";
import FlatDownArrow from "../../assets/icons/flat_down_arrow.svg";
import { useFilter } from "../../context/filter";
import mobileStyles from "./mobile-styles.module.css";
import SchoolContent from "./school-content";
import styles from "./styles.module.css";
import classNames from "classnames";

const SchoolDetails = ({ schoolName, onCenterLocation, onClose, isDemo }) => {
  const [loading, setLoading] = useState(false);
  const [school, setSchool] = useState();
  const [bottomDrag, setBottomDrag] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const panelRef = useRef(null);
  const {
    schoolDirection,
    setSchoolDirection,
    setDatabasePaneOpen,
    databasePaneOpen,
    setSchoolRoute,
  } = useFilter();
  const hasSchoolName = !!schoolName?.length;

  const onToggleInternalClose = () => {
    setSchoolDirection(false);
    setSchoolRoute(false);
    if (hasSchoolName) {
      setDatabasePaneOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (hasSchoolName) {
      setDatabasePaneOpen(true);
      setLoading(true);
      getSchool(schoolName)
        .then((result) => {
          setSchool(result);
          onCenterLocation(result.lat, result.lng);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolName]);

  useEffect(() => {
    if (schoolDirection && databasePaneOpen) {
      setDatabasePaneOpen(false);
    }
  }, [schoolDirection, databasePaneOpen]);

  // *************** HANDLE ACTIONS ***************
  useEffect(() => {
    const node = panelRef.current;

    if (node && isDragging) {
      node.addEventListener('touchmove', handleTouchMove, { passive: false });
      node.addEventListener('touchend', handleTouchEnd, { passive: false });
    }

    return () => {
      if (node) {
        node.removeEventListener('touchmove', handleTouchMove);
        node.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging, startY, bottomDrag]);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (!hasSchoolName) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (isDragging && hasSchoolName) {
      e.preventDefault();
      const delta = e.touches[0].clientY - startY;
      setBottomDrag(databasePaneOpen ? delta : Math.abs(delta));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (bottomDrag > 15) {
      const shouldTogglePanel = bottomDrag > 50;
      if (shouldTogglePanel) {
        setDatabasePaneOpen(prev => !prev);
      }
    }
    setBottomDrag(0);
  };
  // *************** HANDLE ACTIONS ***************

  return (
    <>
      <div className={styles.container}>
        <div
          className={`${styles.contentContainer} ${databasePaneOpen ? styles.slideOpen : styles.slideClose} `}
        >
          <SchoolContent isDemo={isDemo} school={school} loading={loading} />
          <div
            className={classNames(
              styles.closeButton2,
              databasePaneOpen ? styles.closeButton2Transform : null
            )}
            onClick={onToggleInternalClose}
          >
            <img
              className={classNames(
                styles.closeButton2Img,
                databasePaneOpen ? styles.closeButton2ImgTransform : null
              )}
              src={databasePaneOpen ? CloseIcon : OpenIcon}
              width={22}
              height={40}
              alt="close"
            />
          </div>
        </div>
      </div>
      <div className={mobileStyles.container}>
        <div
          ref={panelRef}
          className={`${mobileStyles.contentContainer}`}
          style={{
            bottom: databasePaneOpen ? (0 - (isDragging ? bottomDrag : 0)) : `calc(-100vh + ${260 + (isDragging ? bottomDrag : 0)}px + 30px)`,
            transition: isDragging ? 'none' : 'bottom 0.65s ease',
          }}
        >
          <div
            onClick={onToggleInternalClose}
            className={classNames(
              mobileStyles.handleContainer,
              !databasePaneOpen && mobileStyles.handleContainerClosed
            )}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img
              src={databasePaneOpen ? FlatDownArrow : FlatUpArrow}
              alt="handle-icon"
              width={30}
            />
          </div>
          <div className={mobileStyles.content}>
            <SchoolContent school={school} loading={loading} isDemo={isDemo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(SchoolDetails);
