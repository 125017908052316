import { DASHBOARD_ROUTES } from "../../common/route";
import { ICONS_NAME } from "../components/icon";

export const PRODUCTS_MENU = [
  {
    key: "database",
    label: "Database",
    icon: ICONS_NAME.pin,
    route: DASHBOARD_ROUTES.database,
  },
  {
    key: "vault",
    label: "The Vault",
    icon: ICONS_NAME.playButton,
    route: DASHBOARD_ROUTES.vault,
  },
  {
    key: "review",
    label: "Review",
    icon: ICONS_NAME.reviewIcon,
    route: DASHBOARD_ROUTES.review,
    extraRoutes: [DASHBOARD_ROUTES.newReview],
  },
  {
    key: "rules",
    label: "Rules & Regs",
    icon: ICONS_NAME.openBook,
    route: DASHBOARD_ROUTES.rules,
  },
  {
    key: "newsletter",
    label: "Newsletter",
    icon: ICONS_NAME.newspaper,
    route: DASHBOARD_ROUTES.newsletter,
  },
];

export const REVIEW_STATUS = {
  SUBMITTED: "SUBMITTED",
  IN_REVIEW: "IN_REVIEW",
  WAITING_FOR_ADMIN_APPROVAL: "WAITING_FOR_ADMIN_APPROVAL",
  APPROVED: "APPROVED",
  DONE: "DONE",
};

export const REVIEW_LABEL = {
  [REVIEW_STATUS.SUBMITTED]: "Sent for review",
  [REVIEW_STATUS.IN_REVIEW]: "In Review",
  [REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL]: "In Review",
  [REVIEW_STATUS.APPROVED]: "In Review",
  [REVIEW_STATUS.DONE]: "Done",
};

export const ADMIN_REVIEW_LABEL = {
  [REVIEW_STATUS.SUBMITTED]: "Ready for review",
  [REVIEW_STATUS.IN_REVIEW]: "In Review",
  [REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL]: "Waiting for admin approval",
  [REVIEW_STATUS.APPROVED]: "Approved",
  [REVIEW_STATUS.DONE]: "Done",
};

export const VIDEO_TYPE = {
  highlightsFilm: { key: "highlightsFilm", label: "Highlights Film" },
  technicalFilm: { key: "technicalFilm", label: "Technical Film" },
};

export const POSITION = {
  quaterback: { key: "quaterback", label: "Quarterback" },
  receiverTightend: { key: "receiverTightend", label: "Receiver / Tightend" },
  runningBack: { key: "runningBack", label: "Runningback" },
  offensiveLineman: { key: "offensiveLineman", label: "Offensive Lineman" },
  defensiveLineman: { key: "defensiveLineman", label: "Defensive Lineman" },
  linebacker: { key: "linebacker", label: "Linebacker" },
  defensiveBack: { key: "defensiveBack", label: "Defensive Back" },
};

export const VIDEO_TYPE_OPTIONS = [
  {
    value: VIDEO_TYPE.highlightsFilm.key,
    label: VIDEO_TYPE.highlightsFilm.label,
  },
  {
    value: VIDEO_TYPE.technicalFilm.key,
    label: VIDEO_TYPE.technicalFilm.label,
  },
];

export const POSITION_OPTIONS = [
  { value: POSITION.quaterback.key, label: POSITION.quaterback.label },
  {
    value: POSITION.receiverTightend.key,
    label: POSITION.receiverTightend.label,
  },
  { value: POSITION.runningBack.key, label: POSITION.runningBack.label },
  {
    value: POSITION.offensiveLineman.key,
    label: POSITION.offensiveLineman.label,
  },
  {
    value: POSITION.defensiveLineman.key,
    label: POSITION.defensiveLineman.label,
  },
  { value: POSITION.linebacker.key, label: POSITION.linebacker.label },
  { value: POSITION.defensiveBack.key, label: POSITION.defensiveBack.label },
];

export const CONTENT_TYPES = {
  regular: "regular",
  item_with_title: "item_with_title",
  bullet_item_with_title: "bullet_item_with_title",
  title: "title",
};

export const DIVISION1_CONTENT = {
  "Basic Rules": [
    [
      {
        text: "Freshman and Sophomore Year (9th and 10th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Recruiting questionnaires:",
        text: " These are forms from schools to gather basic info about you (like your contact details, academic info, and athletic stats.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Camp brochures:",
        text: " Invitations and info about camps where you can showcase your skills.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic institutional publications:",
        text: " General info about the school, but nothing specific to athletics.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Important note:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " College coaches cannot contact you directly, but they can send these materials.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Junior Year (11th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Starting June 15 after sophomore year:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can start contacting you directly.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "What you can receive anytime:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Brochures for camps",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Recruiting questionnaires",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "NCAA materials:",
        text: " Guides about eligibility and the recruiting process.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic recruiting publications:",
        text: " General info about the school.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "April 1 through the Sunday before the last Wednesday in June:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Official campus visits:",
        text: " A college can pay for you and two family members to visit campus. After this date, schools can offer official visits starting September 1 of your senior year.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Senior Year (12th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Starting July 1:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can contact you off-campus, but only during official contact periods. They are allowed six off-campus, in-person contacts with you.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "First day of senior year classes:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " You can start taking official visits to colleges. You can visit multiple schools, but only one official visit per school.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "September 1:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can call you once a week.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "",
        text: " During contact periods, they can call or text you unlimited times.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Evaluation Days:",
        type: CONTENT_TYPES.heading,
      },
      {
        text: " Coaches can evaluate you in person three times total:",
        type: CONTENT_TYPES.regular,
      },
      {
        title: "",
        text: " One evaluation in the fall (during football season).",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "",
        text: " Two evaluations between April 15 and May 31.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
  schedule: {
    cards: [
      {
        title: "Unofficial Visits",
        text: "You can visit schools on your own (paying your way) at any time. There’s no limit on unofficial visits.",
      },
      {
        title: "Dead Period",
        text: "During this time, coaches cannot have in-person contact with you or your family. They can still email, text, or call.",
      },
      {
        title: "Contact Period",
        text: "Coaches can meet with you in person off-campus, like visiting your home or meeting after a game.",
      },
      {
        title: "Evaluation period",
        text: "Coaches can watch you play or practice but can’t have in-person contact with you.",
      },
      {
        title: "Quiet Period",
        text: "Coaches can talk to you on campus but cannot visit you off-campus. They can still text, call, and Email.",
      },
    ],
  },
  "Extra Tips": [
    [
      {
        title: "Start early:",
        text: " Understand these rules as early as possible to stay ahead of the process.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Track your communications:",
        text: " Keep a log of when coaches reach out or invite you for visits.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Follow up:",
        text: " If you’re not hearing from schools, consider reaching out during open communication periods or attending camps.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
};

export const DIVISION2_CONTENT = {
  "Basic Rules": [
    [
      {
        text: "Freshman and Sophomore Year (9th and 10th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Recruiting questionnaires:",
        text: " Coaches can send you basic forms to gather information about you.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Camp brochures:",
        text: " Info about camps where you can showcase your skills.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic institutional publications:",
        text: " General info about the college or university.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Important note:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            During this time, college coaches{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>cannot</span>{" "}
            have direct, in-person contact with you. You can still attend camps
            or events where coaches may be present, but no recruiting
            conversations are allowed.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Junior Year (11th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive anytime:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Brochures for camps",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Recruiting questionnaires",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "NCAA materials:",
        text: " Information about NCAA eligibility and recruiting.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic recruiting publications:",
        text: " General info about the school.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Starting June 15 after sophomore year:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can begin calling, texting, emailing, or messaging you directly. They can also send recruiting materials at this time.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Key points:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can now communicate with you through phone calls and messages, but in-person contact is limited.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "",
        text: " This is a good time to start building relationships with coaches.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Senior Year (12th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Starting June 15 after junior year:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            Coaches are allowed to make{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              unlimited calls and texts
            </span>
            . In-person contact is also allowed, but only during official
            contact periods.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Official visits:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            Starting{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              June 15 after junior year
            </span>{" "}
            you can begin taking{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              official visits
            </span>{" "}
            to schools. The school can pay for your travel, lodging, and meals.
            You can take up to{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              five official visits
            </span>
            , but only{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              one per school
            </span>
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Contact Periods:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " During designated contact periods, coaches can have in-person, off-campus contact with you (such as visiting your home or meeting you after a game). This is usually the best time to schedule meetings with coaches.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Evaluation Days:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            Coaches can evaluate you in person at your high school or at
            camps/combines, but they cannot talk to you in person at these
            events. Coaches are allowed to evaluate you{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              three times
            </span>{" "}
            total: once in the fall and twice between April and May.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
  schedule: {
    cards: [
      {
        title: "Dead Period",
        text: (
          <>
            Coaches cannot have{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              in-person contact
            </span>{" "}
            with you or your family during a dead period, but they can still
            communicate via text, call, or email.
          </>
        ),
      },
      {
        title: "Quiet Period",
        text: "Coaches cannot meet you in person off-campus, but they are allowed to talk with you on the phone, text, or email. You can still visit campus for unofficial visits (at your own expense) during this time.",
      },
      {
        title: "Contact Period",
        text: "During a contact period, coaches are allowed to meet with you in person off-campus (like visiting your home or meeting after a game).",
      },
      {
        title: "Evaluation Period",
        text: "During this period, coaches can watch you play or practice but cannot have in-person contact. They are evaluating your skills and performance.",
      },
    ],
  },
  "Extra Tips": [
    [
      {
        title: "Start communicating early:",
        text: " Reach out to coaches as soon as they are allowed to communicate with you (June 15 after sophomore year).",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Track your contacts:",
        text: " Keep notes on when coaches contact you and what they’re asking for.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Take advantage of visits:",
        text: " Official visits are a great opportunity to get a feel for the campus and the team — make sure to take advantage of them when offered.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
};

export const DIVISION3_CONTENT = {
  "Basic Rules": [
    [
      {
        text: "Freshman and Sophomore Year (9th and 10th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Recruiting questionnaires:",
        text: " Forms to gather information about you (contact details, academic info, and athletic stats.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Camp brochures:",
        text: " Invitations to attend camps where you can show off your skills.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic institutional publications:",
        text: " General school information that isn't related to athletics.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Important note:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            While Division III recruiting rules are more flexible than Division
            I and II, coaches{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>cannot</span>{" "}
            engage in{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              in-person recruiting conversations
            </span>{" "}
            with you during your freshman and sophomore years. However, they can
            send you recruiting materials.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Junior Year (11th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive anytime:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Brochures for camps",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Recruiting questionnaires",
        text: " ",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "NCAA materials:",
        text: " Information about NCAA eligibility and recruiting.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Non-athletic recruiting publications:",
        text: " General info about the school.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Contact with coaches:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Starting June 15 after sophomore year:",
        text: " Coaches can begin calling, texting, emailing, or messaging you directly.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "In-person contact: ",
        text: (
          <>
            Unlike Division I and II, Division III coaches have more
            flexibility. They can start{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              off-campus in-person contact
            </span>{" "}
            with you at any point{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              after June 15 of your junior year
            </span>
            , including meeting you at your school or home.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Unofficial Visits:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " You can visit the campus at any time, even before your junior year, but these are at your own expense (the school does not pay for your travel, meals, or lodging).",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Senior Year (12th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Starting July 1 after junior year:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            Coaches can make{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              unlimited calls and texts
            </span>
            , and you can meet with them off-campus.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "Official visits:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            You can start taking official visits to Division III schools
            starting{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              January 1 of your junior year
            </span>
            . These are visits paid for by the college (including travel, meals,
            and lodging). You're allowed to take{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              unlimited official visits
            </span>
            , but{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              only one per school
            </span>
            .
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },

      {
        text: "Key Points:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "No athletic scholarships: ",
        text: "Division III schools do not offer athletic scholarships. Instead, they provide financial aid based on academic achievements and need.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Less restrictive recruiting: ",
        text: "Coaches can communicate more freely, and there are fewer restrictions on when and how often they can contact you compared to Division I and II.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
  schedule: {
    cards: [
      {
        title: "Dead Period",
        text: "Division III does not have official dead periods, meaning there is more flexibility for communication and visits throughout the year. However, recruiting still pauses during certain holidays and quiet periods for campus events.",
      },
      {
        title: "Quiet Period",
        text: "During a quiet period, coaches are not allowed to have off-campus contact but can still communicate with you via phone, email, or text and can meet with you on campus.",
      },
      {
        title: "Contact Period",
        text: (
          <>
            Division III coaches can have in-person, off-campus contact with you
            at any time after{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              June 15 of your junior year
            </span>
            . This makes the recruiting process more flexible.
          </>
        ),
      },
    ],
  },
  "Extra Tips": [
    [
      {
        title: "Stay engaged:",
        text: " Since Division III recruiting is more flexible, it’s important to keep an open line of communication with coaches.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Official visits:",
        text: " These visits are a great way to experience campus life, and Division III schools allow more official visits than other divisions.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Focus on academics:",
        text: " Financial aid in Division III is primarily based on academic performance, so maintaining strong grades is crucial.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
};

export const NAIA_CONTENT = {
  "General Differences from NCAA": [
    [
      {
        title: "No Recruiting Calendars: ",
        text: (
          <>
            Unlike the NCAA, the NAIA does{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>not </span> have
            strict recruiting calendars. Coaches can contact athletes at any
            time during their high school career.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "No Limit on Contacts: ",
        text: 'NAIA coaches have no restrictions on how often they can call, text, or email athletes. There are no "dead periods" or "quiet periods" like in the NCAA. This makes NAIA recruiting more flexible and open.',
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  "Basic Rules": [
    [
      {
        text: "Freshman and Sophomore Year (9th and 10th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Recruiting questionnaires:",
        text: " Forms to gather information about you (contact details, academic info, and athletic stats.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Camp brochures:",
        text: " Invitations to attend camps where you can show off your skills.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "General communication:",
        text: " NAIA coaches can send you messages, emails, or letters at any time, even during your freshman and sophomore years.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "In-person contact:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: "Coaches can visit your high school or attend your games, but they cannot have direct contact with you at these events unless you initiate the conversation.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Junior and Senior Year (11th and 12th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Unlimited communication:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can reach out to you via phone, text, email, or social media with no restrictions. Unlike NCAA Division I and II, there are no blackout periods or times when coaches have to limit contact.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },

      {
        text: "Official Visits:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            You can take{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              official visits
            </span>{" "}
            at any point during your junior or senior year. These are paid for
            by the school and typically cover travel, meals, and lodging. There
            is{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>no limit</span>{" "}
            to the number of official visits you can take to NAIA schools, but
            you’re generally only allowed{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              one visit per school
            </span>
            .
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },

      {
        text: "In-person recruiting:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " NAIA coaches can meet with you off-campus (for example, visiting your home or school), attend your games, and evaluate your athletic abilities without restrictions.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  "Academic Requirements for NAIA Eligibility": [
    [
      {
        text: (
          <div style={{ marginBottom: "20px" }}>
            To qualify to play NAIA sports, you need to meet{" "}
            <span
              style={{
                textDecoration: "underline",
              }}
            >
              two out of three
            </span>{" "}
            of these academic requirements:
          </div>
        ),
        type: CONTENT_TYPES.heading,
      },
      {
        title: "High School GPA: ",
        text: (
          <>
            You need to graduate high school with a{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              minimum 2.0 GPA
            </span>
            .
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "ACT or SAT scores: ",
        text: (
          <>
            You need an{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              18 on the ACT
            </span>{" "}
            or a{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              970 on the SAT
            </span>{" "}
            (math and reading sections only).
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Class Rank: ",
        text: (
          <>
            You need to graduate in the{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>top 50%</span> of
            your high school class.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  schedule: {
    cards: [
      {
        title: "No Dead or Quiet Periods",
        text: "NAIA recruiting is much more open. Coaches can reach out to you year-round without restrictions on when or how often they can contact you.",
      },

      {
        title: "Official vs. Unofficial Visits",
        text: (
          <>
            <span style={{ fontWeight: "500", color: "#fff" }}>
              Official Visits:
            </span>{" "}
            These are paid for by the school and allow you to experience campus
            life. NAIA has no limit on how many schools you can officially
            visit, but typically only one visit per school is allowed.
            <br />
            <br />
            <span style={{ fontWeight: "500", color: "#fff" }}>
              Unofficial Visits:
            </span>{" "}
            You can take as many unofficial visits as you’d like, but these are
            at your own expense.
          </>
        ),
      },
    ],
  },

  "Extra Tips": [
    [
      {
        title: "Reach out early: ",
        text: "Since NAIA recruiting is flexible, don't wait for coaches to contact you. Feel free to reach out to schools you're interested in early in your high school career.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Keep your grades up: ",
        text: "While athletic performance is important, meeting the academic requirements is crucial for eligibility.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Take advantage of visits: ",
        text: "Since you can take official visits at any time, make sure to visit schools early to get a sense of where you’d like to play.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
};

export const JUCO_CONTENT = {
  "General Overview": [
    [
      {
        title: "No Recruiting Calendars: ",
        text: (
          <>
            Unlike the NCAA, the NAIA does{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>not </span> have
            strict recruiting calendars. JUCO coaches can contact athletes at
            any point during their high school career, making recruiting more
            flexible and open.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "No Limit on Contacts: ",
        text: 'JUCO coaches are not restricted in how often they can call, text, email, or message athletes. There are no "dead periods" or "quiet periods" like in NCAA divisions, meaning coaches can reach out to you throughout the year without any restrictions.',
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "More Immediate Playing Time: ",
        text: "In JUCO, athletes often have the chance to start and play regularly right away, compared to NCAA schools where competition for playing time might be tougher, especially in Division I.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Flexible Academic Requirements: ",
        text: "JUCO programs tend to have more flexible academic eligibility standards. While NCAA schools have strict GPA and test score requirements for athletes, many junior colleges only require full-time enrollment and making progress toward a degree.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Easier Transfers: ",
        text: "JUCO athletes frequently transfer to NCAA or NAIA schools after developing their skills and improving their academic standing. NCAA schools often recruit from JUCO programs because of the talent and experience athletes gain while at junior college.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  "Basic Rules": [
    [
      {
        text: "Freshman and Sophomore Year (9th and 10th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "What you can receive:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "Recruiting questionnaires:",
        text: " JUCO coaches can send you forms to gather your contact information, academic stats, and athletic performance data.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Camp brochures:",
        text: " Invitations to camps where you can demonstrate your skills.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "General communication:",
        text: " JUCO coaches can contact you at any time, even during your freshman and sophomore years. There are no restrictions on communication.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        text: "In-person contact:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: "JUCO coaches can attend your games and visit your high school, but they typically cannot have direct, in-person contact with you during these events unless you initiate the conversation.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
    [
      {
        text: "Junior and Senior Year (11th and 12th Grade)",
        type: CONTENT_TYPES.title,
      },
      {
        text: "Communication with coaches:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can call, text, email, or message you at any time. There are no restrictions on how or when JUCO coaches can contact you.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "",
        text: " You can reach out to JUCO coaches whenever you're interested in a program or have questions.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },

      {
        text: "Official Visits:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: (
          <>
            You can start taking{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>
              official visits
            </span>{" "}
            to junior colleges during your junior year. These visits are paid
            for by the school and cover things like travel, meals, and lodging.
            There is usually{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>no limit</span>{" "}
            to the number of official visits you can take, but typically you can
            only visit a particular school{" "}
            <span style={{ fontWeight: "500", color: "#fff" }}>once</span>.
          </>
        ),
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },

      {
        text: "In-person recruiting:",
        type: CONTENT_TYPES.heading,
      },
      {
        title: "",
        text: " Coaches can meet with you off-campus (such as at your home or school) and attend your games or practices to evaluate your performance.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  "Academic Requirements for JUCO Eligibility": [
    [
      {
        title: "Open Eligibility: ",
        text: "JUCO eligibility requirements are often more flexible than NCAA or NAIA requirements. Many junior colleges have an open admissions policy, meaning you don’t need a specific GPA or test score to play.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "NJCAA Academic Standards: ",
        text: "While individual schools may have their own standards, most NJCAA programs require that you are making progress toward a degree and maintain full-time enrollment status (12 or more credit hours per term).",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],

  schedule: {
    cards: [
      {
        title: "No Dead or Quiet Periods",
        text: "Like NAIA, JUCO recruiting is more relaxed. Coaches can reach out to you at any time, and there are no blackout periods or quiet periods where contact is restricted.",
      },

      {
        title: "Official vs. Unofficial Visits",
        text: (
          <>
            <span style={{ fontWeight: "500", color: "#fff" }}>
              Official Visits:
            </span>{" "}
            Paid for by the school, allowing you to visit the campus, meet the
            team, and experience campus life. There is no limit to how many
            official visits you can take to different JUCOs.
            <br />
            <br />
            <span style={{ fontWeight: "500", color: "#fff" }}>
              Unofficial Visits:
            </span>{" "}
            You can visit the campus on your own (at your expense) as often as
            you like.
          </>
        ),
      },
    ],
  },

  "Benefits of JUCO": [
    [
      {
        title: "Playing Time: ",
        text: "JUCO athletes often have the opportunity to start and play regularly, which can lead to quicker development compared to sitting on the bench at larger schools.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Academics: ",
        text: "JUCO can be a good option if you need to improve your grades or test scores before transferring to a four-year college.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Flexible Recruiting: ",
        text: "JUCO recruiting rules are more relaxed, meaning coaches can be more active and available throughout the recruiting process.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Transfers: ",
        text: "JUCO athletes often use their time at a junior college to prepare to transfer to an NCAA or NAIA school with more experience and possibly a scholarship offer.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
  "Extra Tips": [
    [
      {
        title: "Be proactive: ",
        text: "Since JUCO coaches can contact you at any time, make sure you’re active in reaching out to programs that interest you.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Focus on academics: ",
        text: "Even though the eligibility requirements are more flexible, doing well academically can open up more opportunities to transfer to a four-year college down the line.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
      {
        title: "Consider your goals: ",
        text: "JUCO can be a great option if you want more playing time, need to improve your grades, or are looking for a stepping stone to a larger program.",
        type: CONTENT_TYPES.bullet_item_with_title,
        margin: 1,
      },
    ],
  ],
};
