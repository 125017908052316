import React, { useEffect, useState } from "react";
import { generateFirebaseKey } from "../../../../../../../common/services/firebase";
import { removeUndefined } from "../../../../../../../common/utils/object";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import Text from "../../../../../../components/text";
import styles from "./styles.module.css";

const DEFAULT_RATES = ["", "", "", "", ""];

const ReviewQuestion = ({ question, onClose, onSave, canHaveBlueQuestion }) => {
  const [key, setKey] = useState(question?.key);
  const [title, setTitle] = useState(question?.title);
  const [rates, setRates] = useState(question?.rates || DEFAULT_RATES);
  const [equalTo, setEqualTo] = useState(question?.equalTo);
  const [equalToNotes, setEqualToNotes] = useState(question?.equalToNotes);
  const [equalToOrLess, setEqualToOrLess] = useState(question?.equalToOrLess);
  const [equalToOrLessNotes, setEqualToOrLessNotes] = useState(
    question?.equalToOrLessNotes
  );
  const [equalToOrGreater, setEqualToOrGreater] = useState(
    question?.equalToOrGreater
  );
  const [equalToOrGreaterNotes, setEqualToOrGreaterNotes] = useState(
    question?.equalToOrGreaterNotes
  );
  const [blueQuestion, setBlueQuestion] = useState();

  useEffect(() => {
    setKey(question?.key);
    setTitle(question?.title);
    setRates(question?.rates || DEFAULT_RATES);
    setEqualTo(question?.equalTo);
    setEqualToNotes(question?.equalToNotes);
    setEqualToOrLess(question?.equalToOrLess);
    setEqualToOrLessNotes(question?.equalToOrLessNotes);
    setEqualToOrGreater(question?.equalToOrGreater);
    setEqualToOrGreaterNotes(question?.equalToOrGreaterNotes);
    setBlueQuestion(question?.blueQuestion);
  }, [question]);

  const onChangeRate = (rateIndex, rateValue) => {
    setRates((prev) => {
      const newState = [...prev];
      if (rateValue === false) {
        newState[rateIndex] = null;
      } else if (rateValue === true) {
        newState[rateIndex] = "";
      } else {
        newState[rateIndex] = rateValue;
      }
      return newState;
    });
  };

  const onSaveQuestion = () => {
    const newQuestion = {
      title,
      rates,
      equalTo,
      equalToNotes,
      equalToOrLess,
      equalToOrLessNotes,
      equalToOrGreater,
      equalToOrGreaterNotes,
      blueQuestion,
      active: true,
    };
    newQuestion.key = key ?? generateFirebaseKey();
    onSave(removeUndefined(newQuestion));
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <Text className={styles.title}>Question</Text>
          <Input
            inputClassName={styles.questionInput}
            placeholder="Question"
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </div>
        <Button variant="primary" onClick={onClose}>
          Exit Question Without Saving
        </Button>
      </div>
      <div className={styles.ratesContainer}>
        {rates.map((rate, rateIndex) => {
          const rateIsValid = rate !== null;
          return (
            <div
              className={styles.rateContainer}
              key={`question_save_key_${rateIndex}`}
            >
              <Text className={styles.rate}>{rateIndex + 1}</Text>
              <div>
                <input
                  type="checkbox"
                  id={rateIndex}
                  checked={rateIsValid}
                  onChange={(evt) =>
                    onChangeRate(rateIndex, evt.target.checked)
                  }
                />
              </div>
              {rateIsValid ? (
                <Input
                  value={rate}
                  onChange={(evt) => onChangeRate(rateIndex, evt.target.value)}
                />
              ) : null}
            </div>
          );
        })}
      </div>
      <div className={styles.equalContainer}>
        <div>
          <Input
            type="number"
            inputClassName={styles.equalToInput}
            label="Equal to"
            placeholder="Equal to"
            value={equalTo}
            onChange={(evt) => setEqualTo(evt.target.value)}
          />
          <textarea
            placeholder="Equal to notes"
            className={styles.equalToNotes}
            value={equalToNotes}
            onChange={(evt) => setEqualToNotes(evt.target.value)}
          />
        </div>
        <div>
          <Input
            type="number"
            inputClassName={styles.equalToInput}
            label="Equal to or Less than"
            placeholder="Equal to or Less than"
            value={equalToOrLess}
            onChange={(evt) => setEqualToOrLess(evt.target.value)}
          />
          <textarea
            placeholder="Equal to or Less than"
            className={styles.equalToNotes}
            value={equalToOrLessNotes}
            onChange={(evt) => setEqualToOrLessNotes(evt.target.value)}
          />
        </div>
        <div>
          <Input
            type="number"
            inputClassName={styles.equalToInput}
            label="Equal to or Greater than"
            placeholder="Equal to or Greater than"
            value={equalToOrGreater}
            onChange={(evt) => setEqualToOrGreater(evt.target.value)}
          />
          <textarea
            placeholder="Equal to or Greater than"
            className={styles.equalToNotes}
            value={equalToOrGreaterNotes}
            onChange={(evt) => setEqualToOrGreaterNotes(evt.target.value)}
          />
        </div>
      </div>
      {canHaveBlueQuestion || question?.blueQuestion ? (
        <div className={styles.blueQuestionContainer}>
          <input
            type="checkbox"
            checked={blueQuestion}
            onChange={(evt) => setBlueQuestion(evt.target.checked)}
          />
          <Text>
            If you select this checkbox and the athlete scores 1 in this
            question, all the other questions from this section will equal to 1
            and only this one will be printed.
          </Text>
        </div>
      ) : null}
      <div className={styles.saveButtonContainer}>
        <Button variant="outline" onClick={onSaveQuestion}>
          Save Question
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ReviewQuestion);
