import React, { useEffect, useState } from "react";
import SaveFilled from "../../../assets/icons/save-filled.svg";
import SaveOutline from "../../../assets/icons/save-outline-white.svg";
import { useFilter } from "../../../context/filter";
import { getSchoolImage } from "../../../utils/images";
import ActionButtons from "../action-buttons";
import Coach from "../coach";
import styles from "./styles.module.css";
import Check from "../../../assets/icons/checkThin.svg";
import Loading from "../../../../Dashboard/components/loading";
import Text from "../../../../Dashboard/components/text";

const SchoolContent = ({ school, loading, isDemo }) => {
  const {
    savedSchools,
    saveSchool,
    removeSavedSchool,
    setSchoolDirection,
    myLocation,
  } = useFilter();

  const [tempDiff, setTempDiff] = useState(0);
  const [tempLoading, setTempLoading] = useState(false);

  async function getTemperature(latitude, longitude) {
    try {
      const pointsResponse = await fetch(
        `https://api.weather.gov/points/${latitude},${longitude}`
      );
      const pointsData = await pointsResponse.json();

      const stationsUrl = pointsData.properties.observationStations;
      const stationsResponse = await fetch(stationsUrl);
      const stationsData = await stationsResponse.json();

      const firstStationUrl = stationsData.features[0].id;
      const observationsUrl = `${firstStationUrl}/observations/latest`;
      const observationsResponse = await fetch(observationsUrl);
      const observationsData = await observationsResponse.json();

      const temperature = observationsData.properties.temperature.value;

      const temperatureUnit =
        observationsData.properties.temperature.unitCode.split(":")[1];

      if (temperature !== null) {
        const temperatureF =
          temperatureUnit === "degC" ? (temperature * 9) / 5 + 32 : temperature;
        return temperatureF;
      } else {
        console.error("Error: null temperature");
        return null;
      }
    } catch (error) {
      console.error("Error fetching temperature:", error);
      return null;
    }
  }

  async function getTemperatureDifference(location1, location2) {
    setTempLoading(true);
    try {
      const temp1 = await getTemperature(
        Object.values(location1)[0].toFixed(4),
        Object.values(location1)[1].toFixed(4)
      );
      const temp2 = await getTemperature(
        Object.values(location2)[0].toFixed(4),
        Object.values(location2)[1].toFixed(4)
      );

      if (temp1 === null || temp2 === null) {
        document.getElementById("tempDifference").textContent = "";

        throw new Error(
          "Failed to fetch temperature for one or both locations"
        );
      }

      const difference = temp1 - temp2;

      console.log(
        "1: " + temp1 + ", 2: " + temp2 + ", diff: " + (temp1 - temp2)
      );

      setTempDiff(difference.toFixed(1));

      return difference.toFixed(1);
    } catch (error) {
      console.error("Error calculating temperature difference:", error);
      return null;
    } finally {
      setTempLoading(false);
    }
  }

  useEffect(() => {
    if (school) {
      const otherLocation = { lat: school.lat, lon: school.lng };
      getTemperatureDifference(otherLocation, myLocation);
    }
  }, [school]);

  function hasSchool(school) {
    for (let i = 0; i < savedSchools.length; i++) {
      if (savedSchools[i].name === school.name) {
        return true;
      }
    }
    return false;
  }

  function handleDemoClick() {
    document.getElementById("saveButton").classList.add(styles.wiggle);
    setTimeout(
      () =>
        document.getElementById("saveButton").classList.remove(styles.wiggle),
      200
    );
  }

  return (
    <>
      {loading && (
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.schoolContainer}>
              <div className={styles.schoolLogoContainer}>
                <div className={styles.loading}>
                  <Loading size={40} />
                  <Text style={{ fontSize: "16px" }}>
                    Loading school info...
                  </Text>
                </div>
              </div>
            </div>

            <div id="saveButton" className={styles.saveButton}>
              <img src={SaveOutline} width={20} height={20} alt="Save" />
              <div>Save School</div>
            </div>
          </div>

          <ActionButtons
            school={school}
            setSchoolDirection={setSchoolDirection}
            isDemo={isDemo}
            isLoading={true}
            myLocation={myLocation}
          />

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Quick Info</div>
            <div className={styles.quickInfoGrid}>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Division</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Conference</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>Status</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    <br />
                  </div>
                  <div className={styles.quickInfoDescription}>
                    Climate Type
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.quickInfoContainer}
              style={{ padding: "10px 30px" }}
            >
              <div className={styles.quickInfo}>
                <Text style={{ color: "#999999" }}>
                  Current temperature analysis
                </Text>
              </div>
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Coaches on X</div>
            <div className={styles.quickInfoContent}></div>
          </div>
        </div>
      )}

      {!loading && school && (
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <div className={styles.schoolContainer}>
              <div className={styles.schoolLogoContainer}>
                <img
                  className={styles.schoolLogo}
                  src={getSchoolImage(school.name)}
                  alt="school-logo"
                />
              </div>

              <div className={styles.schoolNameContainer}>
                <div className={styles.schoolName}>{school.name}</div>
                <div className={styles.schoolAdress}>{school.address}</div>
              </div>
            </div>

            <div
              id="saveButton"
              className={styles.saveButton}
              onClick={() =>
                isDemo
                  ? handleDemoClick()
                  : hasSchool(school)
                  ? removeSavedSchool(school.name)
                  : saveSchool(school)
              }
            >
              <img
                src={hasSchool(school) ? SaveFilled : SaveOutline}
                width={20}
                height={20}
                alt="Save"
              />

              <div className={styles.saveBtnTextContainer}>
                {hasSchool(school) ? (
                  <div className={styles.savedTextContainer}>
                    Saved
                    <img
                      className={styles.savedCheckContainer}
                      src={Check}
                      width={14}
                      height={14}
                    />
                  </div>
                ) : (
                  "Save School"
                )}
              </div>
            </div>
          </div>

          <ActionButtons
            school={school}
            setSchoolDirection={setSchoolDirection}
            isDemo={isDemo}
          />

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Quick Info</div>
            <div className={styles.quickInfoGrid}>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>{school.devision}</div>
                  <div className={styles.quickInfoDescription}>Division</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    {school.conference}
                  </div>
                  <div className={styles.quickInfoDescription}>Conference</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>
                    {school.publicPrivate}
                  </div>
                  <div className={styles.quickInfoDescription}>Status</div>
                </div>
              </div>
              <div className={styles.quickInfoContainer}>
                <div className={styles.quickInfo}>
                  <div className={isDemo && styles.demo}>{school.temp}</div>
                  <div className={styles.quickInfoDescription}>
                    Climate Type
                  </div>
                </div>
              </div>
            </div>
            <div
              className={styles.quickInfoContainer}
              style={{ padding: "10px 30px" }}
            >
              <div className={styles.quickInfo}>
                {tempLoading ? (
                  <Text style={{ color: "#999999" }}>
                    Fetching current temperature...
                  </Text>
                ) : tempDiff > 1 ? (
                  <Text style={{ color: "#999999" }}>
                    Currently{" "}
                    <span style={{ color: "#C38752" }}>
                      {" "}
                      {Math.abs(tempDiff)}° warmer
                    </span>{" "}
                    than your location
                  </Text>
                ) : tempDiff < -1 ? (
                  <Text style={{ color: "#999999" }}>
                    Currently{" "}
                    <span style={{ color: "#4DA5CB" }}>
                      {" "}
                      {Math.abs(tempDiff)}° cooler
                    </span>{" "}
                    than your location
                  </Text>
                ) : (
                  <Text style={{ color: "#999999" }}>
                    Currently the same temperature as your location
                  </Text>
                )}
              </div>
            </div>
          </div>

          <div className={styles.contentContainer}>
            <div className={styles.contentTitle}>Coaches on X</div>
            <div className={styles.quickInfoContent}>
              <Coach isDemo={isDemo} data={school.hc} coachTitle="Head Coach" />
              <Coach
                isDemo={isDemo}
                data={school.dc}
                coachTitle="Defensive Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.oc}
                coachTitle="Offensive Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.wRs}
                coachTitle="Wide Receivers Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.qBs}
                coachTitle="Quarterbacks Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.rBs}
                coachTitle="Runningbacks Caoch"
              />
              <Coach
                isDemo={isDemo}
                data={school.ol}
                coachTitle="Offensive Linemen Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.te}
                coachTitle="Tight Ends Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.dBs}
                coachTitle="Defensive Backs Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.lBs}
                coachTitle="Linebackers Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.dl}
                coachTitle="Defensive Line Coach"
              />
              <Coach
                isDemo={isDemo}
                data={school.rc}
                coachTitle="Recruiting Coordinator"
              />
              <Coach
                isDemo={isDemo}
                data={school.specialists}
                coachTitle="Special Teams Coordinator"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SchoolContent);
