import React from "react";
import { useHistory } from "react-router-dom";
import { USER_PLANS } from "../../../common/constants";
import { BASE_ROUTES } from "../../../common/route";
import Heading1 from "../../components/.heading1";
import Headline from "../../components/.headline";
import Text from "../../components/.text";
import Title from "../../components/.title";
import Button from "../../components/button";
import Carousel from "../../components/carousel";
import Description from "../../components/Description";
import Header from "../../components/header";
import InfoCard from "../../components/info-card";
import pageStyles from "../styles.module.css";
import whatToDo from "../../assets/images/film-whatToDo.png";
import howToDo from "../../assets/images/film-howToDoIt.png";
import whenToDo from "../../assets/images/film-whenToDoIt.png";
import whatToDoIcon from "../../assets/icons/checklist.png";
import howToDoIcon from "../../assets/icons/strategy.png";
import whenToDoIcon from "../../assets/icons/schedule.png";
import styles from "../vault/styles.module.css";

const Vault = () => {
  const history = useHistory();

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.pageTitleContainer}>
        <Title>The Vault</Title>
      </div>
      <Header id="vault" />
      <Description
        id="vault"
        txt="Walking you through everything you need to know to get recruited."
        btn="Get Access"
      />

      <div className={pageStyles.centerCard}>
        <Heading1>The Vault is where you learn</Heading1>
        <div className={pageStyles.centerCardContent}>
          <Text>
            The Vault is our library of short and long-form videos where
            athletes learn everything about the recruitment process—
            <span style={{ fontWeight: "700" }}>and how to master it.</span>
          </Text>
        </div>
      </div>

      <div className={pageStyles.infocards}>
        <InfoCard
          titleContent="What to Do"
          content="Our videos in the Vault will show you exactly what to do during the recruitment process."
          image={whatToDo}
          imageIcon={whatToDoIcon}
        />
        <InfoCard
          titleContent="How to Do It"
          content="You will learn the best, most effective strategies to get you where you need to be."
          image={howToDo}
          imageIcon={howToDoIcon}
        />
        <InfoCard
          titleContent="When to Do It"
          content="We walk through every aspect of the process, including when you need to take certain actions."
          image={whenToDo}
          imageIcon={whenToDoIcon}
        />
      </div>

      <div className={pageStyles.smallSectionContainer}>
        <div className={pageStyles.centerColumn}>
          <Heading1>Key Topics</Heading1>
          <div className={pageStyles.centerDescription}>
            <Text style={{ color: "var(--darkText)" }}>
              Videos are split into Topics, which each focus on a certain aspect
              of what an athlete should know in order to get recruited.
            </Text>
          </div>
        </div>
      </div>

      <div className={pageStyles.carousel}>
        <Carousel cardsList={keyTopicsCards} />
      </div>
      <div className={pageStyles.carouselInfocards}>{keyTopicsCards}</div>

      <div className={pageStyles.centerTitleContainer}>
        <div className={pageStyles.centerColumn}>
          <Heading1>Need something to show your parents?</Heading1>
          <Text>Watch a video from our "Parents" chapter:</Text>

          <div className={pageStyles.videoContainerContainer}>
            <div className={pageStyles.videoContainer}>
              <iframe
                className={pageStyles.video}
                src="https://www.youtube.com/embed/81I6LTGvR9M"
                title="Parents - Interacting with Coaches"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className={pageStyles.sectionContainer}>
        <div className={pageStyles.centerColumn}>
          <div className={pageStyles.headlineContainer}>
            <Headline>Get Access to the Vault</Headline>
          </div>
          <div className={pageStyles.flex}>
            <Button
              onClick={() =>
                history.push({
                  pathname: BASE_ROUTES.plans,
                  search: `?plan=${USER_PLANS.COMPETITOR.key}`,
                })
              }
            >
              See Competitor Plan
            </Button>
          </div>
        </div>
      </div>

      <div className={pageStyles.redGradient} />
    </div>
  );
};

const keyTopicsCards = [
  <InfoCard
    variant="outlineDarkText"
    titleContent="Making Film"
    content={
      <span>
        What makes film effective? Based on our <a>Film Review Equation</a>
      </span>
    }
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Going to Camps"
    content="Position-specific advice on how to approach camps."
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Social Media"
    content="How to effectively use and grow your social media."
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Parents"
    content="What should parents expect, and how should they be involved?"
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Navigating Visits"
    content="How to evaluate a school and what questions to ask coaches."
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Negotiating Offers"
    content="Assess your value to the school to negotiate scholarships."
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Colleges"
    content="What should you look for in a college?"
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="NCAA Rules"
    content="Important NCAA rules and regulations, explained."
  />,
  <InfoCard
    variant="outlineDarkText"
    titleContent="Self-Assessment"
    content="A look at where you stand and how you can get better."
  />,
];

export default React.memo(Vault);
