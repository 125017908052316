import cn from "classnames";
import React from "react";
import Text from "../text";
import styles from "./styles.module.css";
import SearchIcon from "../../../Database/assets/icons/search-white.svg";

const Input = ({
  label,
  className,
  inputClassName,
  labelClassName,
  variant = "regular",
  search,
  ...props
}) => {
  const innerClassName = cn(styles.input, styles[variant], inputClassName, {
    [styles.inputHasLabel]: !!label,
    [styles.inputHasSearchIcon]: !!search,
  })
  return (
    <div className={cn(styles.container, className)}>
      {label ? (
        <Text className={cn(styles.label, labelClassName)}>{label}</Text>
      ) : null}
      {search ? (
        <div className={styles.searchContainer}>
          <img src={SearchIcon} width={15} height={15} />
          <input className={innerClassName} {...props} />
        </div>
      ) : (
        <input
          className={innerClassName}
          {...props}
        />
      )}
    </div>
  );
};

export default React.memo(Input);
