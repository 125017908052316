import cn from "classnames";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { USER_PLANS } from "../../../../common/constants";
import { DASHBOARD_ROUTES } from "../../../../common/route";
import { checkSelectedRouteNavBar } from "../../../../common/utils/routes";
import FullLogo from "../../../assets/images/full-logo.png";
import Logo from "../../../assets/images/logo.png";
import { PRODUCTS_MENU } from "../../../constants";
import Button from "../../button";
import Icon, { ICONS_NAME } from "../../icon";
import Text from "../../text";
import MenuItem from "../menu-item";
import styles from "./styles.module.css";
import classNames from "classnames";

const CommomMenus = ({ onAfterClick, isMobile }) => {
  const location = useLocation();
  const selectedRoute = location.pathname;
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const userPlan = useSelector((state) => state.stripe.userPlan);
  const { navBarOpen: open } = useSelector((state) => state.appState);

  const goToPage = (pageName) => {
    history.push(pageName);
    onAfterClick?.();
  };

  const scrollEvent = () => {
    document.getElementById("mainMenuContainer").classList.add(styles.scroll);
    setTimeout(() => {
      document
        .getElementById("mainMenuContainer")
        .classList.remove(styles.scroll);
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <div
        id="mainMenuContainer"
        onScroll={scrollEvent}
        className={styles.mainMenuContainer}
      >
        <div className={styles.topMenuContainer}>
          <div className={styles.logoContainer}>
            <img
              src={open ? FullLogo : Logo}
              alt="Logo"
              className={open ? styles.fullLogo : styles.logo}
            />
          </div>

          <div className={styles.dashboardContainer}>
            <MenuItem
              openMenu={open}
              title="Dashboard"
              isSelected={checkSelectedRouteNavBar(
                selectedRoute,
                DASHBOARD_ROUTES.base
              )}
              route={DASHBOARD_ROUTES.base}
              iconName={ICONS_NAME.dashboard}
              onAfterClick={onAfterClick}
              isMobile={isMobile}
            />
          </div>
          <div className={styles.productsMenuContainer}>
            <Text
              className={cn(styles.productsText, {
                [styles.productsTextClosed]: !open,
              })}
              variant="description"
            >
              Products
            </Text>
            {PRODUCTS_MENU.map((menu) => {
              const isSelected = checkSelectedRouteNavBar(
                selectedRoute,
                menu.route,
                ...(menu.extraRoutes || [])
              );
              return (
                <MenuItem
                  key={menu.key}
                  openMenu={open}
                  title={menu.label}
                  iconName={menu.icon}
                  isSelected={isSelected}
                  route={menu.route}
                  onAfterClick={onAfterClick}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.bottomMenuContainer}>
          <MenuItem
            openMenu={open}
            title="Help"
            isSelected={checkSelectedRouteNavBar(
              selectedRoute,
              DASHBOARD_ROUTES.help
            )}
            route={DASHBOARD_ROUTES.help}
            iconName={ICONS_NAME.help}
            onAfterClick={onAfterClick}
            isMobile={isMobile}
          />

          {userPlan?.plan !== USER_PLANS.COMPETITOR.key ? (
            <Button
              variant="outlineTertiary"
              className={styles.upgradeButtonContainer}
              onClick={() => goToPage(DASHBOARD_ROUTES.plans)}
              iconName={ICONS_NAME.crown}
              iconSize={25}
            >
              {open || isMobile ? "Upgrade to Competitor" : null}
            </Button>
          ) : null}
        </div>
      </div>

      <div
        onClick={() => goToPage(DASHBOARD_ROUTES.userSettings)}
        className={styles.userButtonContainer}
      >
        <div className={styles.userContainer}>
          <div className={styles.userNameAndIcon}>
            <Icon name={ICONS_NAME.profileIcon} alt="UserAvatar" size={20} />
            {open || isMobile ? (
              <Text>
                {user.firstName} {user.lastName}
              </Text>
            ) : null}
          </div>
          <Icon name={ICONS_NAME.arrow} alt="arrow" size={15} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommomMenus);
