import React, { createRef, useCallback, useEffect, useRef } from "react";
import BasicRulesContent from "../basic-rules-content";
import ScheduleContent from "../schedule-content";
import Text from "../../../components/text";

const TabContent = ({ content }) => {
  if (!content) return null;
  return (
    <div
      style={{
        marginBottom: "40px",
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
        }}
      >
        <Text variant="h2">Contents:</Text>
        <ol style={{ color: "#aaa", marginLeft: "20px", fontSize: "15px" }}>
          {Object.entries(content).map(([key, value]) => (
            <li>
              {key === "schedule" ? "Key Recruiting Periods" : <>{key}</>}
            </li>
          ))}
        </ol>
      </div>

      {Object.entries(content).map(([key, value]) => (
        <>
          {key !== "schedule" ? (
            <BasicRulesContent title={key} content={value} />
          ) : (
            <ScheduleContent content={value} />
          )}
        </>
      ))}
    </div>
  );
};

export default React.memo(TabContent);
