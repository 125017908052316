import { ICONS_NAME } from '../../Dashboard/components/icon';
import { USER_TYPE } from '../../common/constants';
import { ADMIN_ROUTES } from '../../common/route';

export const ADMIN_MENU = [
  { key: 'home', label: 'Home', icon: ICONS_NAME.dashboard, route: ADMIN_ROUTES.base },
  { key: 'database', label: 'Database', icon: ICONS_NAME.pin, route: ADMIN_ROUTES.database, blackListRoles: [USER_TYPE.REVIEWER, USER_TYPE.NEWSLETTER_WRITER] },
  { key: 'vault', label: 'The Vault', icon: ICONS_NAME.playButton, route: ADMIN_ROUTES.vault, blackListRoles: [USER_TYPE.REVIEWER, USER_TYPE.NEWSLETTER_WRITER] },
  { key: 'review', label: 'Review', icon: ICONS_NAME.reviewIcon, route: ADMIN_ROUTES.review, blackListRoles: [USER_TYPE.NEWSLETTER_WRITER] },
  { key: 'newsletter', label: 'Newsletter', icon: ICONS_NAME.newspaper, route: ADMIN_ROUTES.newsletter, blackListRoles: [USER_TYPE.REVIEWER] },
  { key: 'users', label: 'Users', icon: ICONS_NAME.profileIcon, route: ADMIN_ROUTES.users, blackListRoles: [USER_TYPE.REVIEWER, USER_TYPE.NEWSLETTER_WRITER] },
  { key: 'termsAndConditions', label: 'Terms and Conditions', icon: ICONS_NAME.googleDocs, route: ADMIN_ROUTES.termsAndConditions, blackListRoles: [USER_TYPE.REVIEWER, USER_TYPE.NEWSLETTER_WRITER] },
];