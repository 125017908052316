import cn from "classnames";
import React from "react";
import Loading from "../loading";
import styles from "./styles.module.css";

export const LOADING_STYLES = {
  fullscreen: "fullscreen",
  sideLoader: "sideLoader",
}

const Screen = ({
  children,
  className,
  contentContainerClassName,
  loading,
  loadingStyle = LOADING_STYLES.sideLoader,
}) => {
  const sideLoaderLoading = loading && loadingStyle === LOADING_STYLES.sideLoader;
  return (
    <div className={cn(styles.container, className)}>
      {loading && loadingStyle === LOADING_STYLES.fullscreen ? (
        <div className={styles.modalContainer}>
          <Loading size={70} />
        </div>
      ) : null}
      <div className={cn(
        styles.contentContainer,
        contentContainerClassName,
        { [styles.loadingContentContainer]: sideLoaderLoading }
      )}>
        {sideLoaderLoading ? (
          <div className={styles.sideLoaderContainer}>
            <Loading />
          </div>
        ) : null}
        {children}
      </div>
    </div>
  )
};
export default React.memo(Screen);
