import React from "react";
import Text from "../../../components/text";
import { CONTENT_TYPES } from "../../../constants";
import styles from "./styles.module.css";
import classNames from "classnames";

const ContentRenderer = ({ content }) => {
  return (
    <div>
      {content[0].type === CONTENT_TYPES.title && (
        <div className={styles.title}>
          <Text variant="h2">{content[0].text}</Text>
        </div>
      )}
      <div
        className={classNames(
          styles.container,
          (content[0].type === CONTENT_TYPES.heading ||
            (content.length > 1 &&
              content[1].type === CONTENT_TYPES.heading)) &&
            styles.containerWithHeading
        )}
      >
        {content.map((c, index) => (
          <div>
            {c.type === CONTENT_TYPES.regular ? (
              <div className={styles.regular}>
                <Text>{c.text}</Text>
              </div>
            ) : null}
            {c.type === CONTENT_TYPES.bullet_item_with_title ? (
              <div
                className={styles.bulletItemWithTitleContainer}
                style={{ marginLeft: (c.margin ?? 0) * 10 }}
              >
                <Text className={styles.bullet}>&#8226;</Text>
                <Text className={styles.bulletItemWithTitleText}>
                  <Text className={styles.bulletItemWithTitleTitle}>
                    {c.title}
                  </Text>
                  {c.text}
                </Text>
              </div>
            ) : null}
            {c.type === CONTENT_TYPES.item_with_title ? (
              <Text className={styles.itemWithTitleText}>
                <Text className={styles.itemWithTitleTitle}>{c.title}</Text>
                {c.text}
              </Text>
            ) : null}

            {c.type === CONTENT_TYPES.heading ? (
              <div className={styles.heading}>
                <Text variant="h3">{c.text}</Text>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(ContentRenderer);
