import React from "react";
import Text from "../text";
import styles from "./styles.module.css";
import Loading from "../loading";
import LoadingDots from "../loading-dots";

const Button = ({ content, ...props }) => {
  return (
    <div className={styles.loadingContainer}>
      <LoadingDots size={35} />
      <Text variant="h3">{content}</Text>
    </div>
  );
};

export default React.memo(Button);
