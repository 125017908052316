import React from "react";
import Text from "../../../components/text";
import Card from "./card";
import styles from "./styles.module.css";

const ScheduleContent = ({ content }) => {
  const { cards } = content;
  return (
    <div>
      <div className={styles.title}>
      <Text  variant="title" style={{ color: "#777" }}>
        Key Recruiting Periods:
      </Text>
      </div>
      <div className={styles.content}>
        {cards?.length ? (
          <div className={styles.cardsContainer}>
            {cards.map((card) => (
              <Card title={card.title} text={card.text} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(ScheduleContent);
