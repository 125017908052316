import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Database from "../Database/EntryPoint";
import { DASHBOARD_ROUTES } from "../common/route";
import NavBar from './components/nav-bar';
import CoachPlanManager from "./pages/coach-plan-manager";
import Help from './pages/help';
import Home from './pages/home';
import Newsletters from './pages/newsletters';
import Plans from './pages/plans';
import Review from './pages/review';
import ReviewDetails from "./pages/review-details";
import ReviewFeedback from "./pages/review-feedback";
import Rules from "./pages/rules";
import UserSettings from "./pages/user-settings";
import Vault from "./pages/vault";
import VaultSection from "./pages/vault-section";
import VaultTopic from "./pages/vault-topic";
import VideoDetails from "./pages/video-details";
import { useUserOnlineChecker } from "../common/hooks/useUserOnlineChecker";

const App = () => {
  useUserOnlineChecker();
  return (
    <NavBar>
      <Switch>
        <Route path={DASHBOARD_ROUTES.base} exact>
          <Home />
        </Route>
        <Route path={DASHBOARD_ROUTES.database} exact>
          <Database />
        </Route>
        <Route path={DASHBOARD_ROUTES.vault} exact>
          <Vault />
        </Route>
        <Route path={DASHBOARD_ROUTES.videoDetails} exact>
          <VideoDetails />
        </Route>
        <Route path={DASHBOARD_ROUTES.vaultSection} exact>
          <VaultSection />
        </Route>
        <Route path={DASHBOARD_ROUTES.vaultTopic} exact>
          <VaultTopic />
        </Route>
        <Route path={DASHBOARD_ROUTES.review} exact>
          <Review />
        </Route>
        <Route path={DASHBOARD_ROUTES.reviewDetail} exact>
          <ReviewDetails />
        </Route>
        <Route path={DASHBOARD_ROUTES.reviewFeedback} exact>
          <ReviewFeedback />
        </Route>
        <Route path={DASHBOARD_ROUTES.rules} exact>
          <Rules />
        </Route>
        <Route path={DASHBOARD_ROUTES.newsletter} exact>
          <Newsletters />
        </Route>
        <Route path={DASHBOARD_ROUTES.help} exact>
          <Help />
        </Route>
        <Route path={DASHBOARD_ROUTES.plans} exact>
          <Plans />
        </Route>
        <Route path={DASHBOARD_ROUTES.coachPlanManager} exact>
          <CoachPlanManager />
        </Route>
        <Route path={DASHBOARD_ROUTES.userSettings} exact>
          <UserSettings />
        </Route>
        <Redirect to={DASHBOARD_ROUTES.base} />
      </Switch>
    </NavBar>
  );
};

export default React.memo(App);
