import React from "react";
import CampusIcon from "../../../assets/icons/campus.svg";
import CoachesIcon from "../../../assets/icons/coaches.svg";
import DirectionIcon from "../../../assets/icons/direction.svg";
import NicheIcon from "../../../assets/icons/niche.svg";
import WebsiteIcon from "../../../assets/icons/website.svg";
import styles from "./styles.module.css";
import { toast } from "react-toastify";

const SCHOOL_BUTTONS = [
  { title: "Get Route", icon: DirectionIcon, keyLink: "direction" },
  { title: "Niche Review", icon: NicheIcon, keyLink: "nicheReview" },
  { title: "Find Camps", icon: CampusIcon, keyLink: "campLink" },
  { title: "Find Coaches", icon: CoachesIcon, keyLink: "coachInfo" },
  { title: "Visit Site", icon: WebsiteIcon, keyLink: "website" },
];

const ActionButtons = ({ school, setSchoolDirection, isDemo, isLoading }) => {
  const handleButtonClick = (keyLink) => {
    if (!isDemo) {
      if (keyLink === "direction") {
        navigator.geolocation.getCurrentPosition(
          () => setSchoolDirection([school.lat, school.lng]),
          () =>
            toast.error(
              <div>
                Please grant location permission.{" "}
                <a
                  style={{
                    textDecoration: "underline",
                    color: "rgb(231,76,60)",
                  }}
                  href="https://www.napo.net/page/locationpermission"
                  target="_blank"
                >
                  How to do this
                </a>
              </div>
            )
        );
      } else {
        const url = school[keyLink];
        if (!url) return;
        window.open(url, "_blank", "noreferrer");
      }
    } else {
      document.getElementById(keyLink).classList.add(styles.wiggle);
      setTimeout(
        () => document.getElementById(keyLink).classList.remove(styles.wiggle),
        200
      );
    }
  };

  return (
    <div className={styles.schoolButtonsContainer}>
      {SCHOOL_BUTTONS.map((sb) => (
        <div
          id={sb.keyLink}
          className={styles.schoolButtonContainer}
          key={sb.title}
          onClick={() => {
            !isLoading && handleButtonClick(sb.keyLink);
          }}
        >
          <div className={styles.schoolButtonIcon}>
            <img src={sb.icon} alt="icon" className={styles.icon} />
          </div>
          <div className={styles.schoolButtonTitle}>{sb.title}</div>
        </div>
      ))}
    </div>
  );
};

export default React.memo(ActionButtons);
