import React from "react";
import { logout } from "../../../common/utils/auth";
import Button from "../../components/button";
import Text from "../../components/text";
import styles from "./styles.module.css";
import { DASHBOARD_ROUTES } from "../../../common/route";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>Home</Text>
        <div className={styles.buttonsContainer}>
          <Button variant="secondary" onClick={() => history.push(DASHBOARD_ROUTES.base)}>
            Open Dashboard
          </Button>
          <Button variant="primary" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Home);
