import React, { useMemo } from "react";
import cn from "classnames";
import Text from "../.text";
import styles from "./styles.module.css";
import Button from "../button";
import Title from "../.title";
import DatabaseMap from "../../assets/images/database-map-2.png";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";
import databaseGIF from "../../assets/images/database_gif.gif";
import vaultGIF from "../../assets/images/vault_gif.gif";
import reviewGIF from "../../assets/images/review_gif.gif";
import reviewGIFcover from "../../assets/images/review_gif_cover.gif";
import vaultGIFcover from "../../assets/images/vault_gif_cover.gif";
import databaseGIFcover from "../../assets/images/database_gif_cover.gif";

const ProductCard = ({ titleContent, line1, line2, extraContent, variant }) => {
  const history = useHistory();

  const variantStyle = useMemo(() => {
    if (variant === "1") return styles.one;
    if (variant === "2") return styles.two;
    if (variant === "3") return styles.three;
  }, [variant]);

  return (
    <div className={styles.container}>
      <div className={styles.seperateContainer}>
        {variant % 2 === 0 ? (
          <div className={styles.productImgContainer}>
            <img
              className={styles.imgContainerStatic}
              src={vaultGIFcover}
              alt="The Vault"
              onClick={() => history.push(BASE_ROUTES.vault)}
            />
            <img
              className={styles.imgContainer}
              src={vaultGIF}
              alt="The Vault GIF"
            />
          </div>
        ) : null}
        <div className={styles.cardContainer}>
          <Title>{titleContent}</Title>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>
          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}
          <div className={variantStyle} />
        </div>

        {variant % 2 !== 0 ? (
          <div className={styles.productImgContainer}>
            <img
              className={styles.imgContainerStatic}
              src={
                variant === "1"
                  ? databaseGIFcover
                  : variant === "3"
                  ? reviewGIFcover
                  : null
              }
              alt="The Vault"
              onClick={() =>
                variant === "1"
                  ? history.push(BASE_ROUTES.database)
                  : variant === "3"
                  ? history.push(BASE_ROUTES.review)
                  : null
              }
            />
            <img
              className={styles.imgContainer}
              src={
                variant === "1"
                  ? databaseGIF
                  : variant === "3"
                  ? reviewGIF
                  : null
              }
              alt={
                variant === "1"
                  ? "Database GIF"
                  : variant === "3"
                  ? "Film Review GIF"
                  : null
              }
            />
          </div>
        ) : null}
      </div>

      <div className={styles.inBetweenContainer}>
        {variant % 2 === 0 ? (
          <div className={styles.productImgContainer}>
            <img
              className={styles.imgContainerStatic}
              src={vaultGIFcover}
              alt="The Vault"
              onClick={() => history.push(BASE_ROUTES.vault)}
            />
            <img
              className={styles.imgContainer}
              src={vaultGIF}
              alt="The Vault GIF"
            />
          </div>
        ) : null}
        <div className={styles.cardContainer}>
          <div className={styles.titleContainer}>
            <Title>{titleContent}</Title>
            <div className={variantStyle} />
          </div>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>
          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}
        </div>
        {variant % 2 !== 0 ? (
          <div className={styles.productImgContainer}>
            <img
              className={styles.imgContainerStatic}
              src={
                variant === "1"
                  ? databaseGIFcover
                  : variant === "3"
                  ? reviewGIFcover
                  : null
              }
              alt="The Vault"
              onClick={() =>
                variant === "1"
                  ? history.push(BASE_ROUTES.database)
                  : variant === "3"
                  ? history.push(BASE_ROUTES.review)
                  : null
              }
            />
            <img
              className={styles.imgContainer}
              src={
                variant === "1"
                  ? databaseGIF
                  : variant === "3"
                  ? reviewGIF
                  : null
              }
              alt={
                variant === "1"
                  ? "Database GIF"
                  : variant === "3"
                  ? "Film Review GIF"
                  : null
              }
            />
          </div>
        ) : null}
      </div>

      <div className={styles.stackedContainer}>
        <div className={styles.stackedCardContainer}>
          <div className={variantStyle} />
          <Title style={{ marginBottom: "10px" }}>{titleContent}</Title>
          <Text className={styles.contentContainer}>{line1}</Text>
          <Text className={styles.contentContainer}>{line2}</Text>

          <img
            className={styles.imgContainer}
            src={
              variant === "1"
                ? databaseGIF
                : variant === "2"
                ? vaultGIF
                : variant === "3"
                ? reviewGIF
                : null
            }
            alt={
              variant === "1"
                ? "Database GIF"
                : variant === "2"
                ? "The Vault GIF"
                : variant === "3"
                ? "Film Review GIF"
                : null
            }
            onClick={() =>
              variant === "1"
                ? history.push(BASE_ROUTES.database)
                : variant === "2"
                ? history.push(BASE_ROUTES.vault)
                : variant === "3"
                ? history.push(BASE_ROUTES.review)
                : null
            }
          />

          {extraContent ? (
            <div className={styles.extraContentContainer}>{extraContent}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProductCard);
