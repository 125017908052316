import React from "react";
import americanFootballIcon from "../../assets/icons/american-football.svg";
import arrowIcon from "../../assets/icons/arrow.svg";
import bookmarkIcon from "../../assets/icons/bookmark.svg";
import cameraIcon from "../../assets/icons/camera.svg";
import campingTent2Icon from "../../assets/icons/camping-tent-2.svg";
import campingTentIcon from "../../assets/icons/camping-tent.svg";
import checkIcon from "../../assets/icons/check.svg";
import closeIcon from "../../assets/icons/close.svg";
import creditCardIcon from "../../assets/icons/credit-card.svg";
import crownIcon from "../../assets/icons/crown.svg";
import dashboardIcon from "../../assets/icons/dashboard.svg";
import downloadIcon from "../../assets/icons/download.svg";
import featureIcon from "../../assets/icons/feature.svg";
import flatArrowIcon from "../../assets/icons/flat-arrow.svg";
import googleDocsIcon from "../../assets/icons/google-docs.svg";
import groupIcon from "../../assets/icons/group.svg";
import helpIcon from "../../assets/icons/help.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import lampIcon from "../../assets/icons/lamp.svg";
import linkedinIcon from "../../assets/icons/linkedin.svg";
import listIcon from "../../assets/icons/list.svg";
import listBlackIcon from "../../assets/icons/list-black.svg";
import mapIcon from "../../assets/icons/map.svg";
import menuIcon from "../../assets/icons/menu.svg";
import networkIcon from "../../assets/icons/network.svg";
import newspaperIcon from "../../assets/icons/newspaper.svg";
import openBookIcon from "../../assets/icons/open-book.svg";
import padlockIcon from "../../assets/icons/padlock.svg";
import pencilIcon from "../../assets/icons/pencil.svg";
import pinIcon from "../../assets/icons/pin.svg";
import playButtonIcon from "../../assets/icons/play-button.svg";
import plusIcon from "../../assets/icons/plus.svg";
import profileIconIcon from "../../assets/icons/profile-icon.svg";
import randomIcon from "../../assets/icons/random.svg";
import recentIcon from "../../assets/icons/recent.svg";
import recentWhiteIcon from "../../assets/icons/recent-white.svg";
import reportIcon from "../../assets/icons/report.svg";
import reviewIconIcon from "../../assets/icons/review-icon.svg";
import reviewIcon from "../../assets/icons/review.svg";
import trainerIcon from "../../assets/icons/trainer.svg";
import trashBinIcon from "../../assets/icons/trash-bin.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import userProfileIcon from "../../assets/icons/user-profile.svg";
import videoCameraIcon from "../../assets/icons/video-camera.svg";
import worldWideWebIcon from "../../assets/icons/world-wide-web.svg";
import youtubeIcon from "../../assets/icons/youtube.svg";

export const ICONS_NAME = {
  americanFootball: "americanFootball",
  arrow: "arrow",
  bookmark: "bookmark",
  crown: "crown",
  camera: "camera",
  campingTent: "campingTent",
  campingTent2: "campingTent2",
  check: "check",
  close: "close",
  creditCard: "creditCard",
  dashboard: "dashboard",
  download: "download",
  featured: "featured",
  flatArrow: "flatArrow",
  googleDocs: "googleDocs",
  group: "group",
  help: "help",
  instagram: "instagram",
  lamp: "lamp",
  list: "list",
  listBlack: "listBlack",
  linkedin: "linkedin",
  map: "map",
  menu: "menu",
  network: "network",
  newspaper: "newspaper",
  openBook: "openBook",
  padlock: "padlock",
  pencil: "pencil",
  pin: "pin",
  playButton: "playButton",
  plus: "plus",
  profileIcon: "profileIcon",
  random: "random",
  recent: "recent",
  recentWhite: "recentWhite",
  report: "report",
  reviewIcon: "reviewIcon",
  review: "review",
  trainer: "trainer",
  trashBin: "trashBin",
  twitter: "twitter",
  userProfile: "userProfile",
  videoCamera: "videoCamera",
  worldWideWeb: "worldWideWeb",
  youtube: "youtube",
};

const ICONS_MAP = {
  [ICONS_NAME.americanFootball]: americanFootballIcon,
  [ICONS_NAME.arrow]: arrowIcon,
  [ICONS_NAME.bookmark]: bookmarkIcon,
  [ICONS_NAME.crown]: crownIcon,
  [ICONS_NAME.camera]: cameraIcon,
  [ICONS_NAME.campingTent]: campingTentIcon,
  [ICONS_NAME.campingTent2]: campingTent2Icon,
  [ICONS_NAME.check]: checkIcon,
  [ICONS_NAME.close]: closeIcon,
  [ICONS_NAME.creditCard]: creditCardIcon,
  [ICONS_NAME.dashboard]: dashboardIcon,
  [ICONS_NAME.download]: downloadIcon,
  [ICONS_NAME.featured]: featureIcon,
  [ICONS_NAME.flatArrow]: flatArrowIcon,
  [ICONS_NAME.googleDocs]: googleDocsIcon,
  [ICONS_NAME.group]: groupIcon,
  [ICONS_NAME.help]: helpIcon,
  [ICONS_NAME.instagram]: instagramIcon,
  [ICONS_NAME.lamp]: lampIcon,
  [ICONS_NAME.list]: listIcon,
  [ICONS_NAME.listBlack]: listBlackIcon,
  [ICONS_NAME.linkedin]: linkedinIcon,
  [ICONS_NAME.map]: mapIcon,
  [ICONS_NAME.menu]: menuIcon,
  [ICONS_NAME.network]: networkIcon,
  [ICONS_NAME.newspaper]: newspaperIcon,
  [ICONS_NAME.openBook]: openBookIcon,
  [ICONS_NAME.padlock]: padlockIcon,
  [ICONS_NAME.pencil]: pencilIcon,
  [ICONS_NAME.pin]: pinIcon,
  [ICONS_NAME.playButton]: playButtonIcon,
  [ICONS_NAME.plus]: plusIcon,
  [ICONS_NAME.profileIcon]: profileIconIcon,
  [ICONS_NAME.random]: randomIcon,
  [ICONS_NAME.recent]: recentIcon,
  [ICONS_NAME.recentWhite]: recentWhiteIcon,
  [ICONS_NAME.report]: reportIcon,
  [ICONS_NAME.reviewIcon]: reviewIconIcon,
  [ICONS_NAME.review]: reviewIcon,
  [ICONS_NAME.trainer]: trainerIcon,
  [ICONS_NAME.trashBin]: trashBinIcon,
  [ICONS_NAME.twitter]: twitterIcon,
  [ICONS_NAME.userProfile]: userProfileIcon,
  [ICONS_NAME.videoCamera]: videoCameraIcon,
  [ICONS_NAME.worldWideWeb]: worldWideWebIcon,
  [ICONS_NAME.youtube]: youtubeIcon,
};

const Icon = ({ name, size = 20, color = "#FFF", alt, ...props }) => {
  if (!ICONS_MAP[name]) return null;
  return (
    <img
      src={ICONS_MAP[name]}
      style={{ width: size, height: size, fill: color, stroke: color }}
      alt={alt}
      {...props}
    />
  );
};

export default React.memo(Icon);
