import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateAdminRoute from '../common/components/private-admin-route';
import { ADMIN_USERS, USER_NEWSLETTER_PERMISSION, USER_REVIEW_PERMISSION } from "../common/constants";
import { ADMIN_ROUTES } from "../common/route";
import NavBar from "./components/nav-bar";
import Database from './pages/database';
import Home from './pages/home';
import NewNewsletter from './pages/new-newsletter';
import NewVaultTopic from "./pages/new-vault-topic";
import NewVideo from "./pages/new-video";
import Newsletter from './pages/newsletter';
import Review from './pages/review';
import ReviewDetail from './pages/review-detail';
import ReviewsStructure from "./pages/review/reviews-structure";
import Users from "./pages/users";
import Vault from './pages/vault';
import VaultCategories from "./pages/vault-categories";
import VaultTags from "./pages/vault-tags";
import VaultTopics from "./pages/vault-topics";
import TermsAndConditions from "./pages/terms-and-conditions";

const App = () => {
  return (
    <NavBar>
      <Switch>
        <Route path={ADMIN_ROUTES.base} exact>
          <Home />
        </Route>
        <Route path={ADMIN_ROUTES.database} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <Database />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.vault} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <Vault />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.newVideo} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <NewVideo />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.editVideo} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <NewVideo />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.vaultTags} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <VaultTags />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.vaultCategories} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <VaultCategories />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.vaultTopics} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <VaultTopics />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.newVaultTopic} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <NewVaultTopic />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.editVaultTopic} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <NewVaultTopic />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.review} exact>
          <PrivateAdminRoute allowedUserTypes={USER_REVIEW_PERMISSION}>
            <Review />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.reviewDetail} exact>
          <PrivateAdminRoute allowedUserTypes={USER_REVIEW_PERMISSION}>
            <ReviewDetail />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.reviewsStructure} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <ReviewsStructure />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.newsletter} exact>
          <PrivateAdminRoute allowedUserTypes={USER_NEWSLETTER_PERMISSION}>
            <Newsletter />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.newNewsletter} exact>
          <PrivateAdminRoute allowedUserTypes={USER_NEWSLETTER_PERMISSION}>
            <NewNewsletter />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.editNewsletter} exact>
          <PrivateAdminRoute allowedUserTypes={USER_NEWSLETTER_PERMISSION}>
            <NewNewsletter />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.users} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <Users />
          </PrivateAdminRoute>
        </Route>
        <Route path={ADMIN_ROUTES.termsAndConditions} exact>
          <PrivateAdminRoute allowedUserTypes={ADMIN_USERS}>
            <TermsAndConditions />
          </PrivateAdminRoute>
        </Route>
      </Switch>
    </NavBar>
  )
};

export default React.memo(App);
