import React from "react";
import { getImagePreviewUrl } from "../../../../common/utils/storage";
import Text from "../../../components/text";
import styles from "./styles.module.css";
import cn from "classnames";
import Icon, { ICONS_NAME } from "../../../components/icon";

const VerticalNewsletterCard = ({
  abreviatedTitle,
  image,
  _publishedAt,
  onClick,
}) => {
  const imageUrl = getImagePreviewUrl("newsletter", image);
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={styles.imageContainer}>
        {imageUrl ? <img src={imageUrl} alt="preview" className={styles.image} /> : null}
      </div>
      <div className={styles.contentContainer}>
        <Text variant="h3">{abreviatedTitle}</Text>
        <div>
          <Text variant="description">{new Date(_publishedAt).toLocaleDateString()}</Text>
        </div>
      </div>
    </div>
  )
};

export default React.memo(VerticalNewsletterCard);
