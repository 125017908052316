import React from "react";
import Text from "../../../components/text";
import ContentRenderer from "../content-renderer";
import styles from "./styles.module.css";
import Lightbulb from "../../../assets/icons/lamp.svg";

const BasicRulesContent = ({ title, content }) => {
  return (
    <div>
      <div className={styles.title}>
        {title === "Extra Tips:" && (
          <img
            src={Lightbulb}
            width={26}
            style={{ transform: "translateY(-1px)" }}
          />
        )}
        <Text variant="title" style={{ color: "#777" }}>
          {title}:
        </Text>
      </div>

      <div className={styles.content}>
        {content.map((c, index) => (
          <ContentRenderer key={index} content={c} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(BasicRulesContent);
