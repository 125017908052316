import React, { useMemo } from "react";
import Text from "../.text";
import styles from "./styles.module.css";
import Heading1 from "../.heading1";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES } from "../../../common/route";
import Heading2 from "../.heading2";

const InfoCard = ({
  link,
  titleContent,
  content,
  extraContent,
  image,
  imageIcon,
  variant,
  align,
  ...props
}) => {
  const history = useHistory();
  const variantStyle = useMemo(() => {
    if (variant === "outline" || variant === "outlineDarkText")
      return styles.outline;
  }, [variant]);
  const textVariantStyle = useMemo(() => {
    if (variant === "outlineDarkText") return styles.darkText;
  }, [variant]);
  const alignStyle = useMemo(() => {
    if (align === "center") return styles.center;
  }, [align]);
  return (
    <div
      {...props}
      onClick={() => history.push(link)}
      className={cn(
        styles.container,
        { [styles.setCursor]: link },
        variantStyle,
        alignStyle
      )}
    >
      <div className={styles.titleContainer}>
        {variant === "outlineDarkText" ? (
          <Heading2>{titleContent}</Heading2>
        ) : (
          <Heading1>{titleContent}</Heading1>
        )}
      </div>
      <Text className={cn(styles.contentContainer, textVariantStyle)}>
        {" "}
        {content}
      </Text>
      {extraContent ? (
        <div className={styles.extraContentSpacer}>
          <div className={styles.extraContentContainer}>{extraContent}</div>
        </div>
      ) : null}
      {image ? (
        <div className={styles.imageContainer}>
          {imageIcon ? (
            <div className={styles.imageIconContainer}>
              <img className={styles.imageIcon} src={imageIcon} alt="icon" />
            </div>
          ) : null}
          <img className={styles.image} src={image} alt="thumbnail" />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(InfoCard);
