import React, { useState } from "react";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import Text from "../../components/text";
import {
  DIVISION1_CONTENT,
  DIVISION2_CONTENT,
  DIVISION3_CONTENT,
  JUCO_CONTENT,
  NAIA_CONTENT,
} from "../../constants";
import styles from "./styles.module.css";
import TabContent from "./tab-content";
import classNames from "classnames";
import Lightbulb from "../../assets/icons/lamp.svg";

const TABS = [
  { id: "division1", title: "Division I", content: DIVISION1_CONTENT },
  { id: "division2", title: "Division II", content: DIVISION2_CONTENT },
  { id: "division3", title: "Division III", content: DIVISION3_CONTENT },
  { id: "naia", title: "NAIA", content: NAIA_CONTENT },
  { id: "juco", title: "JUCO", content: JUCO_CONTENT },
];

const Rules = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const content = TABS[selectedTab].content;

  return (
    <Screen>
      <PageTitle title="Recruiting rules & Regulations" />
      <div className={styles.tabsContainer}>
        {TABS.map((tab, index) => {
          const isSelected = selectedTab === index;
          return (
            <div key={tab.id} onClick={() => setSelectedTab(index)}>
              <Text
                variant={isSelected ? "regular" : "description"}
                className={classNames(
                  styles.tabTitle,
                  isSelected && styles.tabSelected
                )}
              >
                {tab.title}
              </Text>
            </div>
          );
        })}
      </div>
      {selectedTab !== 3 && selectedTab !== 4 ? (
        <div className={styles.infoContainer}>
          <div className={styles.infoContainerHeading}>
            <img src={Lightbulb} alt="Lightbulb" width={22} />
            <Text variant="h3">Just so you know:</Text>
          </div>
          <div className={styles.infoContainerText}>
            <Text style={{ color: "#aaa" }}>
              As an athlete, it's important to be aware of NCAA recruiting rules
              and calendars to understand when you can expect coaches to respond
              or reach out.
            </Text>
            <Text style={{ color: "#aaa" }}>
              While college coaches are responsible for following these rules,{" "}
              <span style={{ color: "#fff" }}>
                you can still email, call, or DM a coach at any time
              </span>
              . However, remember that coaches may be restricted by NCAA
              regulations on when they can respond to your messages and how they
              may respond.
            </Text>
          </div>
        </div>
      ) : null}

      <div className={styles.tabContent}>
        <TabContent content={content} />
      </div>
    </Screen>
  );
};

export default React.memo(Rules);
