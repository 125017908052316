import React, { useMemo, useState } from "react";
import PageTitle from "../../components/page-title";
import Screen from "../../components/screen";
import { useSelector } from "react-redux";
import Text from "../../components/text";
import Input from "../../components/input";
import Button from "../../components/button";
import styles from "./styles.module.css";
import NewsletterCard from "./newsletter-card";
import { ICONS_NAME } from "../../components/icon";
import NewsletterPaper from "./newsletter-paper";
import cn from "classnames";
import VerticalNewsletterCard from "./vertical-newsletter-card";
import { cloneDeep } from "lodash";

const getSelectedNewsletterTitleDate = (date) => {
  if (!date) return null;
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const suffix = (day) => {
    if (day === 1 || day === 21 || day === 31) return 'st';
    if (day === 2 || day === 22) return 'nd';
    if (day === 3 || day === 23) return 'rd';
    return 'th';
  };
  return `${month} ${day}${suffix(day)}`;
}

const getOrderedNewsletterTags = (newsletters = [], tags = []) => {
  for (let i = 0; i < newsletters.length; i++) {
    const n = newsletters[i];
    n.tags = (n.tags || [])
      .map((tagId) => tags.find((tag) => tag.id === tagId))
      .filter((t) => !!t);
  }
  return newsletters;
};

const Newsletters = () => {
  const { newsletters: reduxNewsletters, tags } = useSelector(
    (state) => state.newsletters
  );
  const loadingNewsletters = useSelector(
    (state) => state.loaders.loadingNewsletters
  );
  const [search, setSearch] = useState("");
  const [selectedNewsletter, setSelectedNewsletter] = useState();
  const [showList, setShowList] = useState(false);

  const newsletters = useMemo(() => {
    if (!reduxNewsletters) return [];
    const orderedNewsletters = cloneDeep(reduxNewsletters).sort((a, b) => (b._publishedAt || 0) - (a._publishedAt || 0));
    const featuredIndex = orderedNewsletters.findIndex(n => n.featured);
    if (featuredIndex >= 0) {
      const featured = orderedNewsletters[featuredIndex];
      orderedNewsletters.splice(featuredIndex, 1);
      orderedNewsletters.splice(1, 0, featured);
    }
    const newNewsletters = getOrderedNewsletterTags(orderedNewsletters, tags);
    setSelectedNewsletter(newNewsletters[0]);
    return newNewsletters;
  }, [reduxNewsletters, tags]);

  const filteredNewsletters = useMemo(() => {
    const searchLC = search?.trim()?.toLowerCase();
    if (!searchLC) return newsletters || [];
    return newsletters.filter((n) => {
      const title = n.title.trim().toLowerCase();
      const abreviatedTitle = n.abreviatedTitle.trim().toLowerCase();
      const nTags = n.tags || [];
      return (
        title.includes(searchLC) ||
        abreviatedTitle.includes(searchLC) ||
        (nTags.length &&
          !!nTags.find((tag) =>
            tag.label.trim().toLowerCase().includes(searchLC)
          ))
      );
    });
  }, [newsletters, search]);

  const onSelectNewsletter = (newsletter) => {
    setShowList(false);
    setSelectedNewsletter(newsletter);
  }

  const onRandomClick = () => {
    let newSelectedNewsletter;
    do {
      const newRandomIndex = Math.floor(Math.random() * newsletters.length);
      newSelectedNewsletter = newsletters[newRandomIndex];
    } while (newSelectedNewsletter?.id === selectedNewsletter?.id);
    onSelectNewsletter(newSelectedNewsletter);
  }

  const onFullListClick = () => {
    setSearch("");
    setShowList(prev => !prev);
  }

  return (
    <Screen loading={loadingNewsletters}>
      <PageTitle title="Newsletter" />
      <div className={styles.pageDescriptionContainer}>
        <Text>
          Introducing the Athletes After Weekly Newsletter – your go-to source for the latest in college football.
          Designed for parents, coaches, and athletes, our newsletter keeps you updated with essential news, expert insights,
          and exclusive content. Stay connected and informed with the Athletes After Weekly Newsletter.
        </Text>
      </div>
      <div className={styles.selectNewsletterTitleContainer}>
        <Text className={styles.selectNewsletterTitle}>Select Newsletter:</Text>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersButtonsContainer}>
          <Button className={styles.filterButton} iconName={ICONS_NAME.recentWhite} onClick={() => onSelectNewsletter(newsletters[0])}>Most Recent</Button>
          <Button className={styles.filterButton} iconName={ICONS_NAME.random} onClick={onRandomClick}>Random</Button>
          <Button
            className={cn(styles.filterButton, { [styles.selectedFilterButton]: showList })}
            iconName={showList ? ICONS_NAME.listBlack : ICONS_NAME.list}
            onClick={onFullListClick}
          >
            <Text className={showList ? styles.selectedFilterButtonText : null}>
              Full list
            </Text>
          </Button>
        </div>
        <div className={styles.filtersButtonsContainer}>
          <Input
            className={styles.searchInput}
            value={search}
            onChange={(evt) => setSearch(evt.target.value)}
            placeholder="Search past newsletters"
            variant="transparent"
            search
          />
        </div>
      </div>
      {showList ? (
        <div className={styles.newsletterVerticalListContainer}>
          {filteredNewsletters.map(n => (
            <VerticalNewsletterCard
              key={`newsletter-vertical-card-${n.id}`}
              {...n}
              onClick={() => onSelectNewsletter(n)}
            />
          ))}
        </div>
      ) : (
        <div className={styles.newsletterHorizontalListContainer}>
          {filteredNewsletters.map((n, i) => {
            const tag = i === 0 ? "mostRecent" : n.featured ? "featured" : undefined;
            return (
              <NewsletterCard
                key={`newsletter-card-${n.id}`}
                {...n}
                onClick={() => onSelectNewsletter(n)}
                isSelected={selectedNewsletter?.id === n.id}
                tag={tag}
              />
            )
          })}
        </div>
      )}
      {(selectedNewsletter && !showList) ? (
        <div className={styles.selectedNewsletterContainer}>
          <Text variant="h1">{getSelectedNewsletterTitleDate(new Date(selectedNewsletter._publishedAt))} Issue</Text>
          <NewsletterPaper {...selectedNewsletter} />
        </div>
      ) : null}
    </Screen>
  );
};

export default React.memo(Newsletters);