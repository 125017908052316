import React from "react";
import pageStyles from "../styles.module.css";
import Text from "../../components/.text";
import Title from "../../components/.title";
import AboutCard from "../../components/about-card";
import Justin from "../../assets/images/justin.webp";
import Max from "../../assets/images/max.webp";
import Bordeaux from "../../assets/images/bordeaux.jpg";
import styles from "./styles.module.css";
import Heading1 from "../../components/.heading1";
import Heading2 from "../../components/.heading2";

function formatCompactNumber(number) {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
}

async function getSubCount() {
  try {
    const response = await fetch(
      "https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UCyIjXN5TEaU-LzMT0iQx_ww&key=AIzaSyBeHGUPJPdxNXGco-Wx5cy4LW0AVJsBdIo"
    );
    const jsonData = await response.json();
    document.getElementById("subCounter").textContent =
      formatCompactNumber(jsonData.items[0].statistics["subscriberCount"]) +
      " Subscribers";
    return jsonData.items[0].statistics["subscriberCount"];
  } catch (error) {
    console.error(error);
    console.log("Error fetching subscriber data");
  }
}

const About = () => {
  getSubCount();

  return (
    <div className={pageStyles.aboutPage}>
      <Title style={{ marginBottom: "70px" }}>About Athletes After</Title>
      <div className={pageStyles.centerCardBg}>
        <Heading1 style={{ opacity: "40%" }}>Our Mission</Heading1>
        <Text>
          Athletes After empowers high school football players to navigate the
          college recruitment process by providing affordable, comprehensive
          tools.
        </Text>
        <Text>
          Our goal is to make collegiate opportunities accessible through a
          robust database, insightful content, and expert film reviews.
        </Text>
      </div>
      <div className={pageStyles.centerTitleContainer}>
        <Title>Our Founders</Title>
      </div>
      <div className={pageStyles.centerColumn}>
        <AboutCard
          headshot={Justin}
          name="Justin DeMild, CEO"
          description={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div>
                From competing at the college football level to creating a
                social media company serving football creators around the US,
                co-founding Athletes After was a natural next step in Justin’s
                career.{" "}
              </div>
              <div>
                Coming from a small farm town in Pennsylvania, his dream was to
                play at the college football level. He achieved this, earning
                DII and DIII offers and eventually finding the perfect fit. Now,
                with his playing career behind him, he wants to help those who
                have the same dreams that he did.{" "}
              </div>
              <div>
                Justin’s experience brings a unique approach as a listed athlete
                and a background in self-marketing and brand building, and he is
                excited to help athletes reach their fullest potential.
              </div>
            </div>
          }
          linkedIn="https://www.linkedin.com/in/justin-demild-183554205/"
          insta="https://www.instagram.com/dotmild/"
        ></AboutCard>
        <AboutCard
          headshot={Max}
          name="Max Muncy, CTO"
          description={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div>
                Fueled by a lifelong passion for athletics and a desire to
                empower athletes beyond competition, Max co-founded Athletes
                After to bridge the gap between athletic excellence and a
                fulfilling future.{" "}
              </div>
              <div>
                His journey began on the football fields of a small Indiana
                town,  dreaming of collegiate glory. He honed his skills on and
                off the field, becoming a standout athlete while also learning
                to be a strategic recruit. So, even with limitations in film
                quality and being undersized, he secured two Division I offers
                and numerous Division II options.{" "}
              </div>
              <div>
                While Max went on to play collegiate football, injuries forced
                him to redefine his path. Drawing from his recruiting experience
                and from coaches he and Justin interviewed, he set out to create
                a platform that equips high school football players with the
                resources they need to navigate the recruiting process.{" "}
              </div>
            </div>
          }
          linkedIn="https://www.linkedin.com/in/max-muncy-054038223/"
          insta="https://www.instagram.com/maxwell_m_muncy/"
        ></AboutCard>
        <AboutCard
          headshot={Bordeaux}
          name="James Bordeaux, CMO"
          description={
            <div
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div>
                James' path to Athletes After wasn't on the football field, but
                through the hustle and success of building a social media
                business. Known as "Bordeaux", he has amassed over 1 million
                followers across all platforms by creating content about NCAA
                football.{" "}
              </div>
              <div>
                His unique perspective comes from years of playing soccer, where
                he also witnessed the challenges in the athletic recruiting
                landscape, igniting his passion to transform the process and
                empower high school athletes.{" "}
              </div>
              <div>
                He believes there is no greater satisfaction than helping others
                reach their goals. Through Athletes After, James is excited to
                equip recruits with the tools and knowledge to achieve dreams
                they once thought impossible.{" "}
              </div>
            </div>
          }
          extra={
            <div className={styles.youtubeInfo}>
              <a href="https://www.youtube.com/@BordeauxYT" target="_blank">
                <Text style={{ fontSize: "14px" }}>@BordeauxYT</Text>
                <p
                  id="subCounter"
                  style={{ fontWeight: "600", fontSize: "14px" }}
                >
                  &nbsp;
                </p>
              </a>
            </div>
          }
          youtube="https://www.youtube.com/@BordeauxYT"
          insta="https://www.instagram.com/jamesbordeaux_/"
          twitter="https://x.com/bordeauxyoutube"
        ></AboutCard>
      </div>

      <div className={pageStyles.centerTitleContainer}>
        <Title>Our Story</Title>
      </div>
      <div className={pageStyles.centerCardBg}>
        <Heading2
          style={{ maxWidth: "20em", opacity: "40%", marginBottom: "20px" }}
        >
          How we found our home, and how we can help you find yours:
        </Heading2>
        <Text>
          Athletes After was born from a passion for football and a commitment
          to leveling the playing field for high school athletes aspiring to
          play at the collegiate level.
        </Text>
        <Text>
          Our journey began with the recognition of a critical gap in the
          recruiting process, where many talented athletes were missing out on
          opportunities due to high costs and a lack of essential information.
          Justin DeMild, our CEO, has always believed in the power of sports to
          transform lives. With a background in business management and a track
          record of launching successful ventures, Justin envisioned a platform
          that could democratize football recruitment.{" "}
        </Text>
        <Text>
          {" "}
          He teamed up with Max Muncy, our CTO, whose expertise in analytics and
          website design brought the technical prowess needed to build such a
          platform. James Bordeaux, our CMO, with his extensive experience in
          content creation and a massive online following, joined the dream of
          Athletes After to ensure our message reached every aspiring athlete.
        </Text>
        <Text>
          Together, we created Athletes After, a comprehensive online platform
          that offers a robust database of colleges and coaches, a video vault
          packed with valuable insights, and an innovative film review service.
          Through our own experiences and dedication, we found our home in
          supporting athletes on their journey to college football. Now, we are
          here to help you find your home.{" "}
        </Text>
        <Text>
          At Athletes After, we provide the tools and knowledge you need to take
          control of your recruitment journey. Our goal is to make college
          football opportunities accessible to all high school athletes,
          ensuring they can achieve both their academic and athletic dreams. Our
          story is one of dedication, collaboration, and a shared vision to
          empower the next generation of football stars. We understand the
          challenges athletes face and are here to support them every step of
          the way. Join us at Athletes After, and let us help you find your home
          in college football.
        </Text>
      </div>

      <div className={pageStyles.centerCardBg} style={{ marginTop: "50px" }}>
        <Heading2 style={{ maxWidth: "20em", opacity: "40%" }}>
          A Special Thank You
        </Heading2>
        <Text style={{ fontWeight: "600" }}>
          Grove City College has been key to our success.
        </Text>
        <Text style={{ maxWidth: "34em", padding: "0 1em" }}>
          With the guidance of the Center for Entrepreneurship + Innovation, we
          turned our dream into reality. Their VentureLab program helped us lay
          the foundation, the Wolverine Venture Battle helped to validate the
          concept, and VentureLaunch brought it to life. Through the E+I Fellows
          Program, we assembled a dedicated team of students, all working toward
          achieving our vision.
        </Text>
      </div>

      <div className={pageStyles.topCenterRedGradient} />
      <div className={pageStyles.redGradient} />
    </div>
  );
};

export default React.memo(About);
