import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_MAX_ONLINE_MESSAGE, MAX_SIMULTANEOUS_LOGINS, UNLIMITED_REVIEWS, USER_PLANS } from "../../../common/constants";
import { finishInitialLoading } from "../../../common/redux/reducers/loaders";
import {
  setReviewsLeft,
  setTotalReviewsLeft,
} from "../../../common/redux/reducers/reviews";
import {
  clearStripeData,
  setStripeData,
} from "../../../common/redux/reducers/stripe";
import { setUser } from "../../../common/redux/reducers/user";
import { httpsCallable } from "../../../common/services/firebase";
import { checkUserCanLogin, getUserSnapshot } from "../../../common/services/firebase/users";
import { logout } from "../../../common/utils/auth";
import { getStartAndEndOfMonth } from "../../../common/utils/time";
import styles from "./styles.module.css";
import {
  loadNewsletters,
  loadReviews,
  loadVault,
  loadVideoInProgress,
} from "./utils";
import LoadingDots from "../loading-dots";
import Logo from "../../assets/images/full-logo.png";

const getStripeUserInfo = httpsCallable("getStripeUserInfoFunc");

const getReviewsLeft = (userPlan, reviews = []) => {
  const plan = USER_PLANS[userPlan?.plan];
  if (!plan) return 0;
  const [startOfMonth, endOfMonth] = getStartAndEndOfMonth();
  return plan.reviews === UNLIMITED_REVIEWS
    ? UNLIMITED_REVIEWS
    : plan.reviews -
    reviews.filter(
      (r) => r._createdAt >= startOfMonth && r._createdAt <= endOfMonth
    ).length;
};

const Loader = ({ children }) => {
  const user = useSelector((state) => state.user);
  const stripeData = useSelector((state) => state.stripe);
  const reviews = useSelector((state) => state.reviews.reviews);
  const reviewsLeft = useSelector((state) => state.reviews.reviewsLeft);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [checkingUserCanLogin, setCheckingUserCanLogin] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (user?.id) {
      setCheckingUserCanLogin(true);
      checkUserCanLogin(user.id)
        .then(userCanLogin => {
          if (!userCanLogin) {
            alert(LOGOUT_MAX_ONLINE_MESSAGE);
            logout();
          }
        })
        .finally(() => setCheckingUserCanLogin(false));
    }
  }, [user?.id]);

  const onFinishInitialLoading = () => {
    setIsLoading(false);
    dispatch(finishInitialLoading());
  };

  const loadStripe = async (userId) => {
    const { data } = await getStripeUserInfo(userId);
    if (data) {
      dispatch(
        setStripeData({
          subscription: data.subscription,
          userPlan: data.userPlan,
        })
      );
    } else {
      dispatch(clearStripeData());
    }
  };

  useEffect(() => {
    if (checkingUserCanLogin) return;
    let unsub;
    if (user?.id) {
      unsub = getUserSnapshot(user.id, async (newUser) => {
        dispatch(setUser(newUser));
        await Promise.all([
          loadStripe(newUser.id),
          loadVault(),
          loadNewsletters(),
          loadVideoInProgress(newUser.id),
          loadReviews(newUser.id),
        ])
          .catch((err) => {
            logout();
          })
          .finally(() => onFinishInitialLoading());
      });
    } else {
      onFinishInitialLoading();
    }
    return () => unsub?.();
  }, [user?.id, user?.stripeId, user?.coachPlan, user?._updatedAt, checkingUserCanLogin]);

  useEffect(() => {
    dispatch(
      setReviewsLeft(getReviewsLeft(stripeData?.userPlan, reviews) || 0)
    );
  }, [reviews, stripeData]);

  useEffect(() => {
    dispatch(
      setTotalReviewsLeft(
        reviewsLeft === "unlimitedReviews"
          ? reviewsLeft
          : reviewsLeft + (user?.extraReviews || 0)
      )
    );
  }, [reviews, reviewsLeft, stripeData, user?.extraReviews]);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <div>
          {/* <Text>Loading User Info</Text> */}
          <img src={Logo} width={150} alt="Athletes After Logo" />
        </div>
        <div style={{ marginBottom: "30px" }}>
          <LoadingDots size={40} />
        </div>
      </div>
    );
  }

  return children;
};

export default React.memo(Loader);
