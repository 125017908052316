const generateUUID = () => {
  const randomStr = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
  });
  return `${randomStr}_${Date.now()}`;
}

export const getBrowserId = () => {
  let browserId = localStorage.getItem('browserId');
  if (!browserId) {
    browserId = generateUUID();
    localStorage.setItem('browserId', browserId);
  }
  return browserId;
};