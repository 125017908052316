import React from "react";
import { getImagePreviewUrl } from "../../../../common/utils/storage";
import Text from "../../../components/text";
import styles from "./styles.module.css";
import cn from "classnames";
import Icon, { ICONS_NAME } from "../../../components/icon";

const NewsletterCard = ({
  abreviatedTitle,
  image,
  _publishedAt,
  onClick,
  isSelected,
  tag,
}) => {
  const imageUrl = getImagePreviewUrl("newsletter", image);
  return (
    <div className={styles.container} onClick={onClick}>
      <div className={cn(styles.imageContainer, { [styles.imageWithTagContainer]: !!tag })}>
        {isSelected ? (
          <div className={styles.selectedTextContainer}>
            <Text className={styles.selectedText}>Selected</Text>
          </div>
        ) : null}
        {tag ? (
          <div className={styles.tagContainer}>
            <Text className={styles.tagText}>
              <Icon name={tag === "mostRecent" ? ICONS_NAME.recent : ICONS_NAME.featured} size={16} />
              {tag === "mostRecent" ? "Most Recent" : "Featured"}
            </Text>
          </div>
        ) : null}
        {imageUrl ? <img src={imageUrl} alt="preview" className={styles.image} /> : null}
      </div>
      <div>
        <Text className={styles.titleText}>{abreviatedTitle}</Text>
      </div>
      <div>
        <Text variant="description">{new Date(_publishedAt).toLocaleDateString()}</Text>
      </div>
    </div>
  )
};

export default React.memo(NewsletterCard);
