import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { COACHES_PLAN, USER_PLANS } from "../../../../common/constants";
import { httpsCallable } from "../../../../common/services/firebase";
import Dropdown from "../../../components/dropdown";
import Text from "../../../components/text";
import CheckoutModal from "../checkout-modal";
import styles from "./styles.module.css";

const purchaseCoachesPlan = httpsCallable("purchaseCoachesPlanFunc");

export const COACHES_PLAN_OPTIONS = [
  {
    value: COACHES_PLAN.prices[0],
    label:
      COACHES_PLAN.prices[0].totalAccounts +
      " Accounts - $" +
      COACHES_PLAN.prices[0].price,
  },
  {
    value: COACHES_PLAN.prices[1],
    label:
      COACHES_PLAN.prices[1].totalAccounts +
      " Accounts - $" +
      COACHES_PLAN.prices[1].price,
  },
];

const ConfirmCoachPlanModal = ({ defaultPaymentMethod, onClose }) => {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [selectedCoachPlanOption, setSelectedCoachPlanOption] = useState(
    COACHES_PLAN_OPTIONS[1]
  );
  const selectedCoachPlan = useMemo(() => {
    return COACHES_PLAN.prices.find(
      (p) => p.id === selectedCoachPlanOption.value.id
    );
  }, [selectedCoachPlanOption]);

  const onConfirm = async () => {
    if (!selectedCoachPlan) return alert("Select a plan first");
    setLoading(true);
    try {
      await purchaseCoachesPlan({
        userId: user.id,
        priceId: selectedCoachPlan.id,
      });
      toast.success("Coach plan purchased");
    } catch (error) {
      if (error.message !== "INTERNAL") {
        toast.error(error.message);
      }
      console.log("error", error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <CheckoutModal
      title="Purchase Coach Plan"
      creditCard={defaultPaymentMethod?.card}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={loading}
      hideCoupon
      gettingCoachesPlan
    >
      <div className={styles.sectionsContainer}>
        <div className={styles.section}>
          <Text variant="h3">Select Plan:</Text>

          <Dropdown
            isSearchable={false}
            options={COACHES_PLAN_OPTIONS}
            value={selectedCoachPlanOption}
            onChange={setSelectedCoachPlanOption}
          />
        </div>

        <div className={styles.card}>
          <Text variant="h3">What Comes with Your Plan:</Text>
          <Text>
            {" "}
            Through this plan, you can invite {
              selectedCoachPlan.totalAccounts
            }{" "}
            athletes access to the Competitor Plan. This is our full-access
            plan, which includes:
          </Text>
          <Text>- Full Database access</Text>
          <Text>- Full Vault Access</Text>
          <Text>- Unlimited Film Reviews</Text>
          <Text>- Weekly Newsletter</Text>
          <Text style={{ color: "red" }}>
            You will not be able to upgrade/downgrade from 15 to 25 accounts or
            vice versa.
          </Text>
        </div>

        <div className={styles.card}>
          <Text variant="h3">Details:</Text>
          <Text>One-time payment of ${selectedCoachPlan.price}</Text>
          <Text>{selectedCoachPlan.totalAccounts} Accounts</Text>
        </div>
      </div>
    </CheckoutModal>
  );
};

export default React.memo(ConfirmCoachPlanModal);
