import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ADMIN_USERS, DATABASE_PERMISSION } from "../common/constants";
import { setAppState } from "../common/redux/reducers/app-state";
import RestrictedPage from "../Dashboard/components/restricted-page";
import App from "./App";
import { FilterProvider } from "./context/filter";

const EntryPoint = ({ isDemo }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setAppState({
        navBarOpen: false,
      })
    );
  }, []);

  return (
    <FilterProvider>
      <RestrictedPage
        restrictedTitle="The Database"
        allowedPlans={isDemo ? [] : DATABASE_PERMISSION}
        allowedUserTypes={isDemo ? [] : ADMIN_USERS}
        database
      >
        <App isDemo={isDemo} />
      </RestrictedPage>
    </FilterProvider>
  );
};

export default React.memo(EntryPoint);
