import React from "react";
import styles from "./styles.module.css";
import Button from "../../components/button";
import { ADMIN_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import { useHistory } from "react-router-dom";

const AppSelection = () => {
  const history = useHistory();
  const navigateTo = (route) => {
    history.push(route)
  };

  return (
    <div className={styles.container}>
      <Button onClick={() => navigateTo(ADMIN_ROUTES.base)}>Admin Panel</Button>
      <Button onClick={() => navigateTo(DASHBOARD_ROUTES.base)} >Dashboard</Button>
    </div>
  )
};

export default React.memo(AppSelection);
