import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { FILM_REVIEW_PRODUCT } from "../../../common/constants";
import { setRedirectData } from "../../../common/redux/reducers/app-redirect";
import { BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import { useQuery } from "../../../common/utils/routes";
import { useHistory } from "react-router-dom";
import Carousel from "../../components/carousel";
import AuditForm from "../../assets/images/Film_Review.png";
import Heading1 from "../../components/.heading1";
import Headline from "../../components/.headline";
import Text from "../../components/.text";
import Title from "../../components/.title";
import AuditCard from "../../components/audit-card";
import Button from "../../components/button";
import Description from "../../components/Description";
import Header from "../../components/header";
import InfoCard from "../../components/info-card";
import pageStyles from "../styles.module.css";

const Review = () => {
  const history = useHistory();
  const wteRef = useRef(null);
  const query = useQuery();
  const dispatch = useDispatch();

  const navigateToSection = query.get("section") === "what-to-expect";

  useEffect(() => {
    // Scroll to myRef.current.offsetTop when component mounts
    if (navigateToSection && wteRef.current) {
      window.scrollTo({
        top: wteRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  }, []);

  const onBuyFilmReview = () => {
    dispatch(
      setRedirectData({
        route: DASHBOARD_ROUTES.plans,
        data: FILM_REVIEW_PRODUCT.key,
      })
    );
    history.push(BASE_ROUTES.login);
  };

  return (
    <div className={pageStyles.page}>
      <div className={pageStyles.pageTitleContainer}>
        <Title>Film Review</Title>
      </div>
      <Header id="review" />
      <Description
        id="review"
        txt="Find out how to improve your film to maximize your potential."
        btn="Get a Film Review"
      />

      <div className={pageStyles.infocards}>
        <InfoCard
          titleContent="How It Works"
          content={
            <ul className={pageStyles.olContainer}>
              <li>Submit your film for review.</li>
              <li>
                We use our Equation to analyze your film for your specific
                position.
              </li>
              <li>Your report is made. This usually takes 5-10 days.</li>
            </ul>
          }
        />
        <InfoCard
          titleContent="The Equation"
          content={
            <div>
              <Text>
                The Equation is a set of benchmarks based on countless
                interviews with coaches and athletes of all levels.{" "}
              </Text>
              <Text>
                It is specific to each position, ensuring you get feedback that
                matters.
              </Text>
            </div>
          }
        />
        <InfoCard
          titleContent="Your Report"
          content="Your report includes an overall grade, assessment of the skills you demonstrated, how to improve your film, and more."
          extraContent={
            <div className={pageStyles.flex}>
              <Button
                onClick={() =>
                  window.scrollTo({
                    top: wteRef.current.offsetTop - 100,
                    behavior: "smooth",
                  })
                }
                variant="outline"
              >
                See Example
              </Button>
            </div>
          }
        />
      </div>

      <div className={pageStyles.smallSectionContainer}>
        <div className={pageStyles.centerColumn}>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "18px",
              color: "var(--darkText)",
            }}
          >
            Good film is a compilation of athletic plays.
          </Text>
          <Text
            style={{
              textAlign: "center",
              fontWeight: "500",
              fontSize: "18px",
              marginTop: "-10px",
            }}
          >
            Great film is an effective advertisement of what the athlete has to
            offer.
          </Text>
        </div>
      </div>

      <div ref={wteRef} className={pageStyles.smallSectionContainer}>
        <div className={pageStyles.centerColumn}>
          <Heading1>What to Expect</Heading1>
          <Text className={pageStyles.centerDescription}>
            When your report is ready, you'll get a report like this one that
            shows you your results and suggestions on how to improve.
          </Text>
          <div className={pageStyles.reviewDocGridContainer}>
            <div className={pageStyles.auditCardsContainer}>{auditCards}</div>
            <div className={pageStyles.reviewDocContainer}>
              <img
                className={pageStyles.reviewDoc}
                src={AuditForm}
                alt="Example Film Review Report"
              />
            </div>
          </div>
          <div className={pageStyles.reviewCarousel}>
            <Carousel cardsList={auditCards} />
          </div>
        </div>
      </div>

      <div className={pageStyles.sectionContainer}>
        <div className={pageStyles.centerColumn}>
          <div className={pageStyles.headlineContainer}>
            <Headline>Purchase Options</Headline>
          </div>
          <div className={pageStyles.infocardsPairContainer}>
            <div className={pageStyles.infocards}>
              <InfoCard
                titleContent="How It Works"
                content={
                  <Text>
                    In celebration of our launch, try just one Film Review for{" "}
                    <span style={{ fontWeight: "700" }}>
                      ${FILM_REVIEW_PRODUCT.price}
                    </span>{" "}
                    to see how you can improve your film.
                  </Text>
                }
                extraContent={
                  <div className={pageStyles.flex}>
                    <Button variant="white" onClick={onBuyFilmReview}>
                      Buy 1 Film Review
                    </Button>
                  </div>
                }
              />
              <InfoCard
                titleContent="Plans"
                content="Our monthly plans offer the most value, including Film Reviews in addition to our Database and Vault."
                extraContent={
                  <div className={pageStyles.flex}>
                    <Button onClick={() => history.push(BASE_ROUTES.plans)}>
                      See Plans
                    </Button>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={pageStyles.redGradient} />
    </div>
  );
};

const auditCards = [
  <AuditCard
    titleContent="General Notes"
    content="In this section, we provide feedback on the first impression you make and evaluate your performance in key areas that enhance your recruiting potential."
  />,
  <AuditCard
    titleContent="Athletic Skills Demonstrated"
    content="This section highlights the athletic skills essential for high-level recruitment and assesses areas where additional film coverage may be needed."
  />,
  <AuditCard
    titleContent="Highlight Quality"
    content=" The fundamental elements you need to get right to effectively market your abilities."
  />,
  <AuditCard
    titleContent="How to Improve"
    content="In this section, you'll receive tailored, objective advice on improving your film. If any areas need enhancement, you'll find specific feedback here on how to elevate your performance."
  />,
];

export default React.memo(Review);
