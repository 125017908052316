import React from "react";
import Text from "../../../../components/text";
import styles from "./styles.module.css";

const Card = ({ title, text }) => {
  return (
    <div className={styles.container}>
      <Text style={{ fontSize: "17px", fontWeight: "600" }}>{title}</Text>
      <Text style={{ color: "#aaa" }}>{text}</Text>
    </div>
  );
};

export default React.memo(Card);
