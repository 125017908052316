import cn from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_ROUTES, DASHBOARD_ROUTES } from "../../../common/route";
import Button from "../button";
import Icon, { ICONS_NAME } from "../icon";
import Text from "../text";
import styles from "./styles.module.css";

const RestrictedPage = ({
  restrictedTitle,
  allowedPlans = [],
  allowedUserTypes = [],
  database,
  children,
}) => {
  const user = useSelector((state) => state.user);
  const { userPlan } = useSelector((state) => state.stripe);
  const history = useHistory();
  const userPlanIsNotAllowed = allowedPlans.length && (!userPlan || !allowedPlans.includes(userPlan.plan));
  const userTypeIsNotAllowed = allowedUserTypes.length && (!user || !allowedUserTypes.includes(user.userType));
  const restricted = userPlanIsNotAllowed && userTypeIsNotAllowed;
    

  return (
    <div>
      {restricted ? (
        <div
          className={cn(styles.modalContainer, {
            [styles.darkBackground]: !!database,
          })}
        >
          <Icon name={ICONS_NAME.padlock} alt="restricted" size={30} />
          <Text className={styles.restrictedTitle}>
            You don’t have access to {restrictedTitle}
          </Text>
          <Button
            variant="outlineTransparent"
            className={styles.upgradeButtonContainer}
            onClick={() => history.push(DASHBOARD_ROUTES.plans)}
          >
            <div className={styles.upgradeButton}>
              <Icon name={ICONS_NAME.crown} alt="Crown" size={25} />
              <Text className={styles.upgradeText}>Upgrade to Get Access</Text>
            </div>
          </Button>
          {database ? (
            <Button
              variant="secondary"
              onClick={() => history.push(BASE_ROUTES.demo)}
            >
              Try Demo
            </Button>
          ) : null}
        </div>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};

export default React.memo(RestrictedPage);
