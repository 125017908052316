import React, { useState } from "react";
import Text from "../../components/text";
import styles from "./styles.module.css";
import { useDropzone } from "react-dropzone";
import Button from "../../components/button";
import _ from "lodash";
import { uploadPdf } from "../../../common/services/firebase/database";
import { TERMS_AND_CONDITIONS_FILES } from "../../../common/constants";
import { getTermsAndConditionsPdfUrl } from "../../../common/utils/storage";

const ACCEPTED_FILE_TYPES = ['.pdf'];

const TermsAndConditions = () => {
  const [fileType, setFileType] = useState();
  const [fileToUpload, setFileToUpload] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const resetState = () => {
    setLoading(false);
    setFileType(undefined);
    setFileToUpload(undefined);
    setError(undefined);
  }

  const onDrop = async (files) => {
    const file = files[0];
    resetState();
    if (file?.name && _.some(ACCEPTED_FILE_TYPES, type => _.endsWith(file.name, type))) {
      setFileToUpload(file);
    } else {
      alert('File type not supported');
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false });

  const onUpload = async () => {
    if (!fileType || !fileToUpload) return;
    setLoading(true);
    try {
      await uploadPdf(`termsAndConditions/${fileType}.pdf`, fileToUpload);
    } catch (error) {
      console.log('error', error);
    }
    resetState();
    setLoading(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.uploadedFilesContainer}>
        <div>
          <Text className={styles.uploadedFileTitle}>Terms of Service</Text>
          <Button onClick={() => window.open(getTermsAndConditionsPdfUrl(TERMS_AND_CONDITIONS_FILES.termsOfService.value), "_blank")}>Open</Button>
        </div>
        <div>
          <Text className={styles.uploadedFileTitle}>Privacy Policy</Text>
          <Button onClick={() => window.open(getTermsAndConditionsPdfUrl(TERMS_AND_CONDITIONS_FILES.privacyPolicy.value), "_blank")}>Open</Button>
        </div>
      </div>
      <div {...getRootProps()} className={`${styles.dragContainer} ${isDragActive && styles.hoverDragContainer}`}>
        <input {...getInputProps()} />
        {!isDragActive && <Text>Drag 'n' drop the .PDF file here, or click to select it</Text>}
        {isDragActive && <Text>Release your file here</Text>}
      </div>
      {error ? (
        <div>
          <Text>Something wen't wrong... try again and check if your file is in the .PDF format</Text>
        </div>
      ) : null}
      {loading ? (
        <div>
          <Text>Uploading...</Text>
        </div>
      ) : null}
      {fileToUpload && !loading && !error ? (
        <div>
          <div className={styles.fileToUploadContainer}>
            <Text>Select the file you are uploading: </Text>
            <div>
              <Text>
                {fileToUpload.name}
              </Text>
            </div>
            <div>
              <input
                type="radio"
                checked={fileType === TERMS_AND_CONDITIONS_FILES.privacyPolicy.value}
                value={TERMS_AND_CONDITIONS_FILES.privacyPolicy.label}
                name={`file_radio_${TERMS_AND_CONDITIONS_FILES.termsOfService.value}`}
                onChange={() => setFileType(TERMS_AND_CONDITIONS_FILES.privacyPolicy.value)}
              />
              <Text>{TERMS_AND_CONDITIONS_FILES.privacyPolicy.label}</Text>
            </div>
            <div>
              <input
                type="radio"
                checked={fileType === TERMS_AND_CONDITIONS_FILES.termsOfService.value}
                value={TERMS_AND_CONDITIONS_FILES.termsOfService.value}
                name={`file_radio_${TERMS_AND_CONDITIONS_FILES.termsOfService.value}`}
                onChange={() => setFileType(TERMS_AND_CONDITIONS_FILES.termsOfService.value)}
              />
              <Text>{TERMS_AND_CONDITIONS_FILES.termsOfService.label}</Text>
            </div>
          </div>
          <div className={styles.fileToUploadButtonContainer}>
            <Button variant="outline" onClick={onUpload} disabled={!fileType || !fileToUpload}>Upload</Button>
            <Button variant="outline" onClick={resetState}>Clear</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(TermsAndConditions);
