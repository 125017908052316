import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { POSITION, REVIEW_STATUS, VIDEO_TYPE } from '../../../Dashboard/constants';
import { USER_TYPE } from "../../../common/constants";
import { changeReview, getReview, submitReviewFeedback } from '../../../common/services/firebase/reviews';
import { getUser } from '../../../common/services/firebase/users';
import { userIsAdmin } from '../../../common/utils/permission';
import Text from "../../components/text";
import ReviewFeedback from './review-feedback';
import ReviewStatus from './review-status';
import ReviewersSelector from "./reviewers-selector";
import styles from './styles.module.css';
import { toast } from "react-toastify";

const ReviewDetail = () => {
  const [review, setReview] = useState();
  const [user, setUser] = useState();
  const [loadingReview, setLoadingReview] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const [feedback, setFeedback] = useState('');
  const { id: reviewId } = useParams();
  const userId = review?.userId;
  const loading = loadingReview || loadingUser;
  const loggedUser = useSelector(state => state.user);
  const isAdmin = userIsAdmin(loggedUser);

  useEffect(() => {
    setLoadingReview(true);
    getReview({ reviewId })
      .then(result => {
        setReview(result);
        setFeedback(result.feedback || '');
      })
      .finally(() => setLoadingReview(false));
  }, [reviewId]);

  useEffect(() => {
    if (userId) {
      setLoadingUser(true);
      getUser({ userId })
        .then(setUser)
        .finally(() => setLoadingUser(false));
    }
  }, [userId]);

  const onStartReview = async () => {
    const newReview = await changeReview(reviewId, { reviewerId: loggedUser.id, status: REVIEW_STATUS.IN_REVIEW });
    setReview(newReview);
  }

  const onSubmitFeedback = async (feedback, publish = false) => {
    let newReview = await submitReviewFeedback({ reviewId, feedback });
    if (publish) {
      newReview = await changeReview(reviewId, { status: REVIEW_STATUS.DONE });
    }
    setReview({ ...review, ...newReview });
  }

  const onAssignUser = async (reviewerId) => {
    const newReview = await changeReview(reviewId, { reviewerId });
    setReview(newReview);
    toast.success("Reviewer assigned");
  };

  const onAskForAdminApproval = async () => {
    const newReview = await changeReview(reviewId, { status: REVIEW_STATUS.WAITING_FOR_ADMIN_APPROVAL });
    setReview(newReview);
  }

  const onAdminApproval = async () => {
    const newReview = await changeReview(reviewId, { status: REVIEW_STATUS.APPROVED });
    setReview(newReview);
  }

  if (loading) return <Text>Loading...</Text>;

  if (!loading && (!review || !user)) return <Text>Review not found...</Text>;

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>{review.title}</Text>
        <ReviewStatus status={review.status} onStartReview={onStartReview} />
      </div>
      <Text>Date: {new Date(review._createdAt).toLocaleDateString()} - {new Date(review._createdAt).toLocaleTimeString()}</Text>
      {isAdmin ? (
        <div className={styles.reviewersContainer}>
          <ReviewersSelector currentReviewer={review.reviewerId} onAssignUser={onAssignUser} />
        </div>
      ) : null}
      <div className={styles.userInfoContainer}>
        <div>
          <Text className={styles.userTitle}>User Info</Text>
        </div>
        <div>
          <Text>Name: {user.firstName} {user.lastName}</Text>
        </div>
        <div>
          <Text>Email: {user.email}</Text>
        </div>
        <div>
          <Text>Phone: {user.phone}</Text>
        </div>
      </div>
      <div className={styles.reviewInfoContainer}>
        <div>
          <Text className={styles.userTitle}>Review Info</Text>
        </div>
        <div>
          <Text>Video Type: {VIDEO_TYPE[review.videoType].label}</Text>
        </div>
        <div>
          <Text>Position: {POSITION[review.position].label}</Text>
        </div>
        <div>
          <Text>Link: {review.link}</Text>
        </div>
      </div>
      {(review.reviewerId === loggedUser.id || loggedUser?.userType === USER_TYPE.ADMIN) ? (
        <ReviewFeedback
          feedback={feedback}
          isTechnical={review.videoType === VIDEO_TYPE.technicalFilm.key}
          position={review.position}
          status={review.status}
          onSubmitFeedback={onSubmitFeedback}
          onAskForAdminApproval={onAskForAdminApproval}
          onAdminApproval={onAdminApproval}
        />
      ) : null}
    </div>
  )
};

export default React.memo(ReviewDetail);
