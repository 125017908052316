import { useEffect } from "react";
import { useSelector } from "react-redux";
import { checkUserCanLogin, updateUserOnlineStatus } from "../services/firebase/users";
import { LOGOUT_MAX_ONLINE_MESSAGE, MINUTES_TO_CHECK_LOGIN } from "../constants";
import { logout } from "../utils/auth";

export const useUserOnlineChecker = () => {
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (user?.id) {
      let pingInterval;
      const sendPing = () => {
        updateUserOnlineStatus(user.id, new Date().toISOString());
        checkUserCanLogin(user.id, true)
          .then(userCanLogin => {
            if (!userCanLogin) {
              alert(LOGOUT_MAX_ONLINE_MESSAGE);
              logout();
            }
          })
      };
      const startPinging = async () => {
        sendPing();
        pingInterval = setInterval(sendPing, 60000 * MINUTES_TO_CHECK_LOGIN);
      };
      startPinging();
      return () => {
        clearInterval(pingInterval);
      };
    }
  }, [user?.id]);
  return null;
};
