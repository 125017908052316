import React, { useLayoutEffect, useState } from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { BASE_ROUTES } from "../common/route";
import Footer from "./components/footer";
import Nav from "./components/nav";
import About from "./pages/about";
import AcceptInvite from "./pages/accept-invite";
import CoachesPlan from "./pages/coaches-plan";
import DatabaseLP from "./pages/database";
import Home from "./pages/home";
import Login from "./pages/login";
import Plans from "./pages/plans";
import Review from "./pages/review";
import Signup from "./pages/signup";
import Vault from "./pages/vault";
import "./styles.css";

const App = () => {
  const [showBanner, setBannerShow] = useState(false);
  const location = useLocation();
  const selectedPage = location.pathname;

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return (
    <div>
      <div className="headerContent">
        <Nav bannerOn={showBanner} />
      </div>
      <div className={showBanner ? "pageExtraPadding" : "page"}>
        <Switch>
          <Route path={BASE_ROUTES.base} exact>
            <Home />
          </Route>
          <Route path={BASE_ROUTES.database} exact>
            <DatabaseLP />
          </Route>
          <Route path={BASE_ROUTES.vault} exact>
            <Vault />
          </Route>
          <Route path={BASE_ROUTES.review} exact>
            <Review />
          </Route>
          <Route path={BASE_ROUTES.about} exact>
            <About />
          </Route>
          <Route path={BASE_ROUTES.plans} exact>
            <Plans />
          </Route>
          <Route path={BASE_ROUTES.acceptInvite} exact>
            <AcceptInvite />
          </Route>
          <Route path={BASE_ROUTES.coachesPlan} exact>
            <CoachesPlan />
          </Route>
          <Route path={BASE_ROUTES.login} exact>
            <Login />
          </Route>
          <Route path={BASE_ROUTES.signup} exact>
            <Signup />
          </Route>
          {/* <Route path={BASE_ROUTES.testPage} exact>
            <TestPage />
          </Route> */}
          <Redirect to={BASE_ROUTES.base} />
        </Switch>
        {selectedPage !== BASE_ROUTES.login &&
          selectedPage !== BASE_ROUTES.signup ? (
          <Footer />
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(App);
