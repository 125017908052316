import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createReview } from "../../../../common/services/firebase/reviews";
import Button from "../../../components/button";
import Dropdown from "../../../components/dropdown";
import Input from "../../../components/input";
import { loadReviews } from "../../../components/loader/utils";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import ReviewsLeft from "../../../components/reviews-left";
import Text from "../../../components/text";
import {
  POSITION_OPTIONS,
  REVIEW_STATUS,
  VIDEO_TYPE_OPTIONS,
} from "../../../constants";
import styles from "./styles.module.css";

const NewReviewModal = ({ visible, onClose }) => {
  const user = useSelector((state) => state.user);
  const { reviewsLeft } = useSelector((state) => state.reviews);
  const [videoType, setVideoType] = useState(VIDEO_TYPE_OPTIONS[0]);
  const [position, setPosition] = useState(POSITION_OPTIONS[0]);
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setVideoType(VIDEO_TYPE_OPTIONS[0]);
    setPosition(POSITION_OPTIONS[0]);
    setLink("");
  }, [visible]);

  const onSubmit = async () => {
    setLoading(true);
    await createReview({
      title: `${user.lastName} Audit`,
      videoType: videoType?.value,
      position: position?.value,
      link,
      status: REVIEW_STATUS.SUBMITTED,
      userId: user.id,
      reviewsLeft,
    });
    loadReviews(user?.id);
    onClose();
    setLoading(false);
  };
  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <Text variant="title">New Review</Text>
        </div>
        <Dropdown
          isSearchable={false}
          className={styles.inputContainer}
          label="Video Type"
          options={VIDEO_TYPE_OPTIONS}
          value={videoType}
          onChange={(option) => setVideoType(option)}
        />
        <Dropdown
          isSearchable={false}
          className={styles.inputContainer}
          label="Position"
          options={POSITION_OPTIONS}
          value={position}
          onChange={(option) => setPosition(option)}
        />
        <Input
          className={styles.inputContainer}
          label="Link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Paste link here"
        />
        <div className={styles.bottomContainer}>
          {loading ? <Loading /> : null}
          <ReviewsLeft getMore />
          <Button
            variant="outlineTertiary"
            className={styles.buttonContainer}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(NewReviewModal);
