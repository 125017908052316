import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ADMIN_USERS, VAULT_PERMISSION } from "../../../common/constants";
import { setVaultSection } from "../../../common/redux/reducers/vault-section";
import { DASHBOARD_ROUTES } from "../../../common/route";
import Button from "../../components/button";
import ContinueWatching from "../../components/continue-watching";
import HorizontalList from "../../components/horizontal-list";
import Input from "../../components/input";
import { loadVault, loadVideoInProgress } from "../../components/loader/utils";
import PageTitle from "../../components/page-title";
import RestrictedPage from "../../components/restricted-page";
import Screen from "../../components/screen";
import SmallVideoCard from "../../components/small-video-card";
import Text from "../../components/text";
import {
  getVideoInProgress,
  getVideosInFixedCategories,
} from "../../utils/vault";
import SearchPage from "./search-page";
import styles from "./styles.module.css";
import Topics from "./topics";

const Vault = () => {
  const [showSearchPage, setShowSearchPage] = useState(false);
  const user = useSelector((state) => state.user);
  const { videos, topics, categories, tags } = useSelector(
    (state) => state.vault
  );
  const loadingVault = useSelector((state) => state.loaders.loadingVault);
  const loadingVideosInProgress = useSelector(
    (state) => state.loaders.loadingVideosInProgress
  );
  const videosInProgress = useSelector(
    (state) => state.videosInProgress.videos
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const videoInProgress = useMemo(() => {
    return getVideoInProgress(videos, videosInProgress);
  }, [videosInProgress, videos]);

  const recentVideos = useMemo(() => {
    return videos.slice(0, 10);
  }, [videos]);

  const {
    featuredVideos,
    // shortVideos,
    // fullInterviewVideos
  } = useMemo(() => {
    return getVideosInFixedCategories(videos);
  }, [videos]);

  const videoCategories = useMemo(() => {
    if (!categories.length || !videos.length) return [];
    const newVideoCategories = {};
    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i];
      newVideoCategories[cat.id] = {
        id: cat.id,
        videos: [],
      };
    }
    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];
      const videoCat = video.category;
      if (videoCat?.length) newVideoCategories[videoCat].videos.push(video);
    }
    return Object.values(newVideoCategories);
  }, [videos, categories]);

  useEffect(() => {
    if (user?.id) {
      loadVideoInProgress(user.id);
    }
  }, [user?.id]);

  useEffect(() => {
    loadVault();
  }, []);

  const goToSection = (title, sectionVideos = []) => {
    if (!title?.length) return;
    dispatch(
      setVaultSection({
        title,
        videos: sectionVideos,
      })
    );
    history.push(DASHBOARD_ROUTES.vaultSection);
  };

  return (
    <RestrictedPage restrictedTitle="The Vault" allowedPlans={VAULT_PERMISSION} allowedUserTypes={ADMIN_USERS}>
      <Screen loading={loadingVault || loadingVideosInProgress}>
        <PageTitle
          title="The Vault"
          ExtraComponent={
            <Input
              placeholder="Search The Vault"
              onFocus={() => setShowSearchPage(true)}
              variant="transparent"
              search
            />
          }
        />
        <ContinueWatching
          video={videoInProgress}
          className={styles.sectionContainer}
        />
        <div className={styles.sectionContainer}>
          <HorizontalList
            id="featured-video"
            title="Featured"
            data={featuredVideos.map((video) => ({ video, videosInProgress }))}
            Component={SmallVideoCard}
            onSeeAllClick={() => goToSection("Featured", featuredVideos)}
            videosFromSection={featuredVideos}
          />
        </div>
        {topics.length ? (
          <div className={styles.sectionContainer}>
            <Text variant="h2">Topics</Text>
            <Topics topics={topics} />
          </div>
        ) : null}
        {/* <div className={styles.sectionContainer}>
          <HorizontalList
            id="short-video"
            title="Shorts"
            data={shortVideos.map(video => ({ video, videosInProgress }))}
            Component={SmallVideoCard}
            videosFromSection={shortVideos}
            onSeeAllClick={() => goToSection("Shorts", shortVideos)}
          />
        </div>
        <div className={styles.sectionContainer}>
          <HorizontalList
            id="full-interview-video"
            title="Full Interviews"
            data={fullInterviewVideos.map(video => ({ video, videosInProgress }))}
            Component={SmallVideoCard}
          />
        </div> */}
        <div className={styles.sectionContainer}>
          <HorizontalList
            id="recent-video"
            title="Recently Uploaded"
            data={recentVideos.map((video) => ({ video, videosInProgress }))}
            Component={SmallVideoCard}
            videosFromSection={recentVideos}
          />
        </div>
        <div>
          {videoCategories.map((vc) => {
            const category = categories.find((c) => c.id === vc.id);
            const categoryVideos = vc.videos;
            if (!category || !categoryVideos?.length) return null;
            const videosSlice = categoryVideos.slice(0, 9);
            return (
              <div
                key={`category-${vc.id}`}
                className={styles.videosCategoryContainer}
              >
                <div className={styles.videoCategoryHeader}>
                  <Text variant="h2">{category.name}</Text>
                  <Button
                    variant="tertiary"
                    className={styles.seeAllContainer}
                    onClick={() => goToSection(category.name, categoryVideos)}
                  >
                    See all
                  </Button>
                </div>
                <div className={styles.recentlyAddedContainer}>
                  {videosSlice.map((vcVideo) => (
                    <SmallVideoCard
                      key={`category-video-${vcVideo.id}`}
                      video={vcVideo}
                      videosInProgress={videosInProgress}
                      videosFromSection={categoryVideos}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {showSearchPage ? (
          <SearchPage
            videos={videos}
            onClose={() => setShowSearchPage(false)}
            videosInProgress={videosInProgress}
            tags={tags}
          />
        ) : null}
      </Screen>
    </RestrictedPage>
  );
};

export default React.memo(Vault);
