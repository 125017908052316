import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../common/route";
import {
  getNewsletters,
  getTags,
  updateSelectedNewsletter,
} from "../../../common/services/firebase/newsletter";
import Button from "../../components/button";
import Text from "../../components/text";
import Input from "../../components/input";
import NewsletterCard from "./newsletter-card";
import styles from "./styles.module.css";
import Dropdown from "../../components/dropdown";

const Newsletter = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [loadingNewsltters, setLoadingNewsletters] = useState(true);
  const [loadingTags, setLoadingTags] = useState(true);
  const [featuredPaper, setFeaturedPaper] = useState(undefined);
  const [loadingFeatured, setLoadingFeatured] = useState(false);
  const [search, setSearch] = useState("");
  const [tags, setTags] = useState([]);
  const history = useHistory();

  const loadNewsletters = useCallback(() => {
    setLoadingNewsletters(true);
    getNewsletters({ showDeleted: false })
      .then(newNewsletters => {
        setNewsletters(newNewsletters);
        setFeaturedPaper(newNewsletters?.find(n => n.featured)?.id);
      })
      .finally(() => setLoadingNewsletters(false));
  }, []);

  useEffect(() => {
    loadNewsletters();
  }, [loadNewsletters]);

  useEffect(() => {
    setLoadingTags(true);
    getTags({ showDeleted: false })
      .then(setTags)
      .finally(() => setLoadingTags(false));
  }, []);

  const filteredNewsletters = useMemo(() => {
    const searchLC = search?.trim()?.toLowerCase();
    if (!searchLC) return newsletters || [];
    return newsletters.filter(n => {
      const name = n.title.trim().toLowerCase();
      return name.includes(searchLC);
    });
  }, [newsletters, search]);

  const dropdownNewsletters = useMemo(() => {
    return newsletters.map(n => ({ value: n.id, label: n.title }));
  }, [newsletters]);

  if (loadingNewsltters || loadingTags) return <Text>Loading...</Text>;

  const onChangeSelectedNewsletter = async (newsletterId) => {
    setLoadingFeatured(true);
    try {
      await updateSelectedNewsletter("featured", featuredPaper, newsletterId);
      setFeaturedPaper(newsletterId);
    } catch (error) {
      console.log('error', error)
    }
    setLoadingFeatured(false);
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>Newsletter</Text>
        <Button
          variant="primary"
          onClick={() => history.push(ADMIN_ROUTES.newNewsletter)}
        >
          New Newsletter
        </Button>
      </div>
      <div className={styles.filtersContainer}>
        <Input label="Search" className={styles.searchInput} placeholder="Title search" value={search} onChange={evt => setSearch(evt.target.value)} />
        <Dropdown
          label="Featured"
          options={dropdownNewsletters}
          value={featuredPaper}
          onChange={option => onChangeSelectedNewsletter(option.value)}
          onClear={() => onChangeSelectedNewsletter(undefined)}
          disabled={loadingFeatured}
          className={styles.dropdown}
        />
      </div>
      <div>
        {filteredNewsletters.length ? (
          filteredNewsletters.map((newsletter) => (
            <NewsletterCard
              key={newsletter.id}
              newsletter={newsletter}
              newslettersTags={tags}
              loadNewsletters={loadNewsletters}
            />
          ))
        ) : (
          <Text>No newsletters created</Text>
        )}
      </div>
    </div>
  );
};

export default React.memo(Newsletter);
