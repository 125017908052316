import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  COACHES_PLAN,
  PLANS_ORDER,
  USER_PLANS,
} from "../../../../common/constants";
import { httpsCallable } from "../../../../common/services/firebase";
import Text from "../../../components/text";
import CheckoutModal from "../checkout-modal";
import PlanCard from "../plan-card";
import styles from "./styles.module.css";
import classNames from "classnames";

const createSubscription = httpsCallable("createSubscriptionFunc");
const updateSubscription = httpsCallable("updateSubscriptionFunc");

const ACTION = {
  subscribe: "subscribe",
  upgrade: "upgrade",
  downgrade: "downgrade",
};

const ConfirmPlanModal = ({ selectedPlan, defaultPaymentMethod, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [isMonthlyPrice, setIsMonthlyPrice] = useState(true);
  const { userPlan } = useSelector((state) => state.stripe);
  const user = useSelector((state) => state.user);
  const plan = USER_PLANS[selectedPlan];
  const planName = plan.label;
  const card = defaultPaymentMethod?.card;

  const previousPlan = useMemo(() => {
    return USER_PLANS[userPlan?.plan];
  }, [userPlan]);

  const action = useMemo(() => {
    if (!userPlan) return ACTION.subscribe;
    const planOrder = PLANS_ORDER.findIndex((poKey) => poKey === plan.key);
    const currentPlanOrder = PLANS_ORDER.findIndex(
      (poKey) => poKey === userPlan.plan
    );
    if (planOrder > currentPlanOrder) return ACTION.upgrade;
    return ACTION.downgrade;
  }, [userPlan, plan.key]);

  const onConfirm = async (coupon) => {
    setLoading(true);
    try {
      const priceId = plan.prices[isMonthlyPrice ? "monthly" : "yearly"][0].id;
      if (action === ACTION.subscribe) {
        await createSubscription({ priceId, userId: user.id, coupon });
        toast.success("Welcome to your new plan!");
      } else {
        await updateSubscription({
          priceId,
          itemId: userPlan.item.id,
          userId: user.id,
          coupon,
        });
        toast.success("Plan updated");
      }
    } catch (error) {
      if (error.message?.toLowerCase() !== "internal") {
        toast.error(error.message);
      }
      console.log("error", error);
    }
    setLoading(false);
    onClose();
  };

  return (
    <CheckoutModal
      title="Confirm Plan"
      description={
        <Text>
          Are you sure you want to {action} to the{" "}
          <Text variant="h4">{planName}</Text> plan?
        </Text>
      }
      creditCard={card}
      loading={loading}
      onClose={onClose}
      onConfirm={onConfirm}
      showSubscriptionWarning
      plan={plan}
      monthly={isMonthlyPrice}
    >
      <div>
        <div
          style={{
            display: "flex",
            marginBottom: 20,
            gap: 10,
            alignItems: "center",
          }}
        >
          <div
            className={classNames(isMonthlyPrice && styles.selected)}
            style={{ cursor: "pointer" }}
            onClick={() => setIsMonthlyPrice(true)}
          >
            <Text style={{ color: isMonthlyPrice ? "white" : "grey" }}>
              Monthly
            </Text>
          </div>
          <div
            className={classNames(!isMonthlyPrice && styles.selected)}
            style={{ cursor: "pointer" }}
            onClick={() => setIsMonthlyPrice(false)}
          >
            <Text style={{ color: isMonthlyPrice ? "grey" : "white" }}>
              Yearly
            </Text>
          </div>
        </div>
        <div className={styles.container}>
          {previousPlan ? (
            <PlanCard
              title="Current Plan"
              planKey={previousPlan.key}
              hideButton
            />
          ) : null}
          <PlanCard
            title={previousPlan ? "New Plan" : undefined}
            planKey={plan.key}
            hideButton
            showMonthly={isMonthlyPrice}
          />
        </div>
      </div>
    </CheckoutModal>
  );
};

export default React.memo(ConfirmPlanModal);
