import React, { useCallback, useEffect, useState } from "react";
import { POSITION_OPTIONS, VIDEO_TYPE } from "../../../../Dashboard/constants";
import {
  getReviewsStructure,
  saveReviewsStructure,
} from "../../../../common/services/firebase/reviews";
import Button from "../../../components/button";
import Text from "../../../components/text";
import ReviewsStructureDetails from "./reviews-structure-details";
import styles from "./styles.module.css";
import classNames from "classnames";

const ReviewsStructure = () => {
  const [reviewsStructure, setReviewsStrcture] = useState([]);
  const [selectedReviewStructure, setSelectedReviewStructure] = useState();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const loadReviewsStructure = useCallback(async () => {
    setLoading(true);
    const resp = await getReviewsStructure({});
    setReviewsStrcture(resp);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadReviewsStructure();
  }, [loadReviewsStructure]);

  if (loading) return <Text>Loading...</Text>;

  const onSaveStructure = async (sections, position, docName, isTechnical) => {
    setSaving(true);
    await saveReviewsStructure(sections, position.value, isTechnical, docName);
    await loadReviewsStructure();
    setSelectedReviewStructure(undefined);
    setSaving(false);
  };

  if (selectedReviewStructure)
    return (
      <ReviewsStructureDetails
        reviewStructure={selectedReviewStructure.reviewStructure?.sections}
        position={selectedReviewStructure.position}
        isTechnical={!!selectedReviewStructure.isTechnical}
        onClose={() => setSelectedReviewStructure(undefined)}
        onSave={onSaveStructure}
      />
    );

  return (
    <div className={styles.container}>
      <Text className={styles.title}>Reviews Structure</Text>
      <Text className={styles.subtitle}>
        Add or edit the questions structure for each position in the Review
        section of the Dashboard
      </Text>
      <div className={styles.videoTypeContainer}>
        <Text className={styles.videoTypeTitle}>
          {VIDEO_TYPE.highlightsFilm.label}
        </Text>
        {POSITION_OPTIONS.map((position) => {
          const reviewStructure = reviewsStructure.find(
            (rs) => rs.position === position.value
          );
          return (
            <div
              className={classNames(
                styles.positionContainer,
                !!reviewStructure
                  ? styles.positionContainerEdit
                  : styles.positionContainerAdd
              )}
              key={`position_${position.value}`}
            >
              <Text>{position.label}</Text>
              <Button
                variant="primary"
                onClick={() =>
                  setSelectedReviewStructure({ position, reviewStructure })
                }
              >
                {!!reviewStructure ? "Edit" : "Add"}
              </Button>
            </div>
          );
        })}
      </div>
      <div className={styles.videoTypeContainer}>
        <Text className={styles.videoTypeTitle}>
          {VIDEO_TYPE.technicalFilm.label}
        </Text>
        {POSITION_OPTIONS.map((position) => {
          const reviewStructure = reviewsStructure.find(
            (rs) => rs.position === position.value && rs.isTechnical
          );
          return (
            <div
              className={classNames(
                styles.positionContainer,
                !!reviewStructure
                  ? styles.positionContainerEdit
                  : styles.positionContainerAdd
              )}
              key={`position_${position.value}`}
            >
              <Text>{position.label}</Text>
              <Button
                variant="primary"
                onClick={() =>
                  setSelectedReviewStructure({
                    position,
                    reviewStructure,
                    isTechnical: true,
                  })
                }
              >
                {!!reviewStructure ? "Edit" : "Add"}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(ReviewsStructure);
