import React, { useCallback, useEffect, useState } from "react";
import {
  USERS_CAN_BE_CHANGED,
  USER_TYPE,
  USER_TYPE_LABEL,
} from "../../../common/constants";
import { getUsers, updateUser } from "../../../common/services/firebase/users";
import Button from "../../components/button";
import Text from "../../components/text";
import styles from "./styles.module.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadUsers = useCallback(() => {
    setLoading(true);
    getUsers({})
      .then(setUsers)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  if (loading) return <Text>Loading...</Text>;

  const onChangeUser = async (userId, userType) => {
    updateUser({ id: userId, userType });
    loadUsers();
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>Users</Text>
      </div>
      <div className={styles.userCard}>
        <Text style={{ flex: 5, fontWeight: "bold", fontSize: 20 }}>Name</Text>
        <Text style={{ flex: 5, fontWeight: "bold", fontSize: 20 }}>Email</Text>
        <Text style={{ flex: 3, fontWeight: "bold", fontSize: 20 }}>
          User Type
        </Text>
        <Text style={{ flex: 5, fontWeight: "bold", fontSize: 20 }}>
          Change to:
        </Text>
      </div>
      {users.map((user) => {
        const userType = user.userType;
        if ([USER_TYPE.ADMIN, USER_TYPE.SUPER_ADMIN].includes(userType))
          return null;
        return (
          <div className={styles.userCard}>
          <Text style={{ flex: 5 }}>
            {user.firstName} {user.lastName}
          </Text>
            <Text style={{ flex: 5 }}>
              {user.email}
            </Text>
            <Text style={{ flex: 3 }}>{USER_TYPE_LABEL[userType]}</Text>
            <div style={{ flex: 5 }}>
              <div
                style={{
                  display: USERS_CAN_BE_CHANGED[userType] ? "flex" : "none",
                  gap: 10,
                }}
              >
                {userType !== USER_TYPE.REVIEWER ? (
                  <Button
                    variant="outline"
                    onClick={() => onChangeUser(user.id, USER_TYPE.REVIEWER)}
                  >
                    Reviewer
                  </Button>
                ) : null}
                {userType !== USER_TYPE.NEWSLETTER_WRITER ? (
                  <Button
                    variant="outline"
                    onClick={() =>
                      onChangeUser(user.id, USER_TYPE.NEWSLETTER_WRITER)
                    }
                  >
                    Newsletter Writter
                  </Button>
                ) : null}
                {userType !== USER_TYPE.FREE ? (
                  <Button
                    variant="outline"
                    onClick={() => onChangeUser(user.id, USER_TYPE.FREE)}
                  >
                    Normal User
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Users);
