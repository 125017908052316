import _ from "lodash";
import React, { useEffect, useState } from "react";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import Text from "../../../../../components/text";
import ReviewQuestion from "./review-question";
import styles from "./styles.module.css";

const ReviewSection = ({ section, onClose, onSave, isTechnical }) => {
  const [key, setKey] = useState(section?.key);
  const [title, setTitle] = useState(section?.title);
  const [questions, setQuestions] = useState(section?.questions || []);
  const [selectedQuestion, setSelectedQuestion] = useState();

  useEffect(() => {
    setKey(section?.key);
    setTitle(section?.title);
    setQuestions(section?.questions || []);
    setSelectedQuestion(undefined);
  }, [section]);

  const saveQuestion = (newQuestion) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const prevQuestionsIndex = newQuestions.findIndex(
        (q) => q.key === newQuestion.key
      );
      if (prevQuestionsIndex >= 0)
        newQuestions[prevQuestionsIndex] = newQuestion;
      else newQuestions.push(newQuestion);
      return newQuestions;
    });
    setSelectedQuestion(undefined);
  };

  const onSaveSection = () => {
    const newSection = {
      title,
      questions,
      active: true,
    };
    newSection.key = key ?? _.upperCase(_.deburr(title));
    onSave(newSection);
  };

  const toggleQuestionActive = (questionKey) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const toggleQuestion = newQuestions.find((q) => q.key === questionKey);
      toggleQuestion.active = !toggleQuestion.active;
      return newQuestions;
    });
  };

  if (selectedQuestion)
    return (
      <ReviewQuestion
        question={selectedQuestion}
        onClose={() => setSelectedQuestion(undefined)}
        onSave={saveQuestion}
        canHaveBlueQuestion={
          isTechnical && !questions.some((q) => q.blueQuestion)
        }
      />
    );

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div>
          <Text className={styles.title}>Section</Text>
          <Input
            inputClassName={styles.sectionNameInput}
            placeholder="Section name"
            value={title}
            onChange={(evt) => setTitle(evt.target.value)}
          />
        </div>
        <Button variant="primary" onClick={onClose}>
          Exit Section Without Saving
        </Button>
      </div>
      <div className={styles.questionsContainer}>
        {questions.map((question) => {
          return (
            <div
              className={styles.questionContainer}
              key={`question_${question.key}`}
            >
              <Text className={styles.questionTitle}>{question.title}</Text>
              <div className={styles.questionRatesContainer}>
                {question.rates.map((rate, rateIndex) => {
                  return (
                    <div
                      className={styles.ratesContainer}
                      key={`rate_question_${rateIndex}`}
                    >
                      <Text>{rateIndex + 1}</Text>
                      <div>
                        <input
                          type="checkbox"
                          checked={rate !== null}
                          readOnly
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.activeContainer}>
                <input
                  type="checkbox"
                  checked={question.active}
                  onChange={() => toggleQuestionActive(question.key)}
                />
                <Text>Active</Text>
              </div>
              <Button
                variant="primary"
                onClick={() => setSelectedQuestion(question)}
              >
                Edit
              </Button>
            </div>
          );
        })}
      </div>
      <div className={styles.saveButtonContainer}>
        <Button variant="outline" onClick={() => setSelectedQuestion({})}>
          Add new Question
        </Button>
      </div>
      <div className={styles.saveButtonContainer}>
        <Button variant="outline" onClick={onSaveSection}>
          Save Section
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ReviewSection);
