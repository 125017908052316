import React from "react";
import Screen from "../../components/screen";
import Text from "../../components/text";
import PageTitle from "../../components/page-title";
import styles from "./styles.module.css";

const Help = () => {
  return (
    <Screen>
      <div className={styles.container}>
        <div className={styles.section}>
          <PageTitle title="Help" />
          <Text variant="h2">Frequently Asked Questions:</Text>
          <div className={styles.question}>
            <Text variant="h3">Why do I not have access to products?</Text>
            <Text>
              Check what plan you have by navigating to the user profile page
              (the lowest button on the nav bar). Scroll down to check what plan
              you have. Each plan only comes with certain products availible to
              you.
            </Text>
          </div>
          <div className={styles.question}>
            <Text variant="h3">How do I add a default payment method?</Text>
            <Text>
              Navigate to the user profile page (the lowest button on the nav
              bar). Scroll down, and you will se a button called "Payment
              Methods". Click on it and add a card. Once you add a card, press
              the "Set as Default" button next to the card number.
            </Text>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.contact}>
            <Text variant="h2">Contact Us</Text>
            {/* <Text>By phone: 444-444-4444</Text> */}
            <Text>
              By email:{" "}
              <a
                style={{ color: "white", textDecoration: "underline" }}
                href="mailto:info@athletesafter.com"
              >
                info@athletesafter.com
              </a>
            </Text>
          </div>
        </div>
      </div>
    </Screen>
  );
};

export default React.memo(Help);
