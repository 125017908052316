import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { httpsCallable } from "../../../../common/services/firebase";
import CloseIcon from "../../../assets/icons/close.svg";
import Button from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import Loading from "../../../components/loading";
import Modal from "../../../components/modal";
import PaymentMethods from "../../../components/payment-methods";
import Text from "../../../components/text";
import styles from "./styles.module.css";
import { COACHES_PLAN, TERMS_AND_CONDITIONS_FILES, USER_PLANS } from "../../../../common/constants";
import { getTermsAndConditionsPdfUrl } from "../../../../common/utils/storage";
import { useSelector } from "react-redux";

const getPromotionCode = httpsCallable("getPromotionCodeFunc");

const calcDiscount = (value = 0, discountCoupon) => {
  const coupon = discountCoupon?.coupon;
  let newValue = value;
  if (coupon.amount_off) newValue = value - coupon.amount_off / 100;
  if (coupon.percent_off) newValue = value - value * (coupon.percent_off / 100);
  return newValue.toFixed(2);
};

const PromotionCodeInput = ({ loading, value, onChange }) => {
  return (
    <input
      id="discountInput"
      placeholder="Discount Code"
      value={value}
      onChange={onChange}
      className={styles.discountInput}
    />
  );
};

const CheckoutModal = ({
  title,
  description,
  children,
  loading,
  creditCard,
  showSubscriptionWarning,
  onClose,
  onConfirm,
  hideCoupon,
  plan,
  monthly,

  gettingCoachesPlan,
}) => {
  const [discountCoupon, setDiscountCoupon] = useState();
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [agreeCheck, setAgreeCheck] = useState(false);
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(false);
  const [showPaymentMethodsModal, setShowPaymentMethodsModal] = useState(false);

  const { subscription, userPlan } = useSelector((state) => state.stripe);
  const user = useSelector((state) => state.user);

  const previousPlan = useMemo(() => {
    return USER_PLANS[userPlan?.plan];
  }, [userPlan]);

  const coachPlan = useMemo(() => {
    if (!userPlan?.userIsCoach) return;
    return COACHES_PLAN.prices.find(
      (price) => price.id === subscription.metadata.priceId
    );
  }, [subscription?.metadata?.priceId, userPlan?.userIsCoach]);

  let isCurrentPlan = false;
  if (plan) {
    isCurrentPlan = previousPlan === plan;
  }
  const hasCoaches = !!coachPlan;

  useEffect(() => {
    const timeout = setTimeout(async () => {
      try {
        if (!coupon.length) return setDiscountCoupon(undefined);
        setLoadingCoupon(true);
        const resp = await getPromotionCode(coupon);
        if (resp.data) {
          setDiscountCoupon(resp.data);
        }
      } catch (error) {
        console.log("error", error);
        setDiscountCoupon(undefined);
      }
      setLoadingCoupon(false);
    }, 750);
    return () => clearTimeout(timeout);
  }, [coupon]);

  const onPromotionCodeChange = (event) => {
    const { value } = event.target;
    setCoupon(value);
  };

  const checkPrice = () => {
    const prices = plan.prices;
    if (prices) {
      return prices[monthly ? "monthly" : "yearly"][0].price;
    }
    return "";
  };

  const emailLink =
    "mailto:info@athletesafter.com?cc=calebeinolf@gmail.com&subject=Bug%20Report%3A%20Plans&body=User%20" +
    user.firstName +
    "%20" +
    user.lastName +
    "%20(" +
    user.email +
    ")%20had%20an%20issue%20getting%20to%20the%20plan%20they%20wanted.%0A%0A(Please%20describe%20what%20happened%3A%20)";

  return (
    <div>
      {!isCurrentPlan &&
        !hasCoaches &&
        !(previousPlan && gettingCoachesPlan) ? (
        <div className={styles.container}>
          <Text style={{ marginBottom: "6px" }} variant="h1">
            {title}
          </Text>
          <Text>{description}</Text>
          <div className={styles.contentContainer}>{children}</div>
          <div className={styles.sectionContainer}>
            {creditCard ? (
              <div className={styles.paymentContainer}>
                <Text variant="h3">Payment Info:</Text>
                <div className={styles.paymentContentContainer}>
                  <div>
                    <Text>Default card:</Text>
                    <div className={styles.card}>
                      <Text>•••• •••• •••• {creditCard.last4}</Text>
                      <Text variant="description">
                        {creditCard.exp_month}/{creditCard.exp_year}
                      </Text>
                    </div>

                    <div className={styles.addPaymentContainer}>
                      <Button
                        onClick={() => setShowPaymentMethodsModal(true)}
                        className={styles.changePaymentButton}
                      >
                        Change Payment Method
                      </Button>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    {!hideCoupon ? (
                      <div>
                        <Text>Coupon:</Text>
                        <div className={styles.discountInputContainer}>
                          <PromotionCodeInput
                            loading={loadingCoupon}
                            value={coupon}
                            onChange={onPromotionCodeChange}
                          />
                          {loadingCoupon ? (
                            <div className={styles.loadingContainer}>
                              <Loading />
                            </div>
                          ) : null}
                          <div
                            className={classNames(
                              styles.discountCancelButton,
                              coupon ? styles.discountCancelButtonActive : null
                            )}
                            onClick={() => setCoupon("")}
                          >
                            <img src={CloseIcon} width={14} height={14} />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {plan ? (
                      <div className={styles.summaryContainer}>
                        <div className={styles.planDiscountContainer}>
                          <Text>
                            {plan.label} Plan: ${checkPrice()}
                          </Text>
                          {discountCoupon ? (
                            <div>
                              <Text>
                                {coupon}: -$
                                {(
                                  checkPrice() -
                                  calcDiscount(checkPrice(), discountCoupon)
                                ).toFixed(2)}
                              </Text>
                            </div>
                          ) : null}
                        </div>

                        <span className={styles.divider} />

                        <div className={styles.totalRow}>
                          <Text>Total: </Text>
                          {discountCoupon ? (
                            <Text>
                              ${calcDiscount(checkPrice(), discountCoupon)}
                            </Text>
                          ) : (
                            <Text>${checkPrice()}</Text>
                          )}
                        </div>
                        <Text style={{ color: "#707070" }}>
                          {monthly ? "paid monthly" : "paid annually"}
                        </Text>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <Text className={styles.danger}>
                  Add a default payment method:
                </Text>
                <div className={styles.addPaymentContainer}>
                  <Button
                    onClick={() => setShowPaymentMethodsModal(true)}
                    className={styles.changePaymentButton}
                  >
                    Add Payment Method
                  </Button>
                </div>
              </div>
            )}

            <Checkbox
              checked={agreeCheck}
              onChange={(evt) => setAgreeCheck(evt.target.checked)}
            >
              I verify that I am at least 18 years of age or have consulted a
              guardian and gotten their approval before purchasing this item
            </Checkbox>
            <Checkbox
              checked={termsAndConditionsCheck}
              onChange={(evt) => setTermsAndConditionsCheck(evt.target.checked)}
            >
              Check to certify that you have read and agree to the Athletes After Co.&nbsp;
              <a href={getTermsAndConditionsPdfUrl(TERMS_AND_CONDITIONS_FILES.termsOfService.value)} target="_blank" style={{ textDecoration: "underline" }}>Terms of Service</a> and&nbsp;
              <a href={getTermsAndConditionsPdfUrl(TERMS_AND_CONDITIONS_FILES.privacyPolicy.value)} target="_blank" style={{ textDecoration: "underline" }}>Privacy Policy</a>
            </Checkbox>
            {showSubscriptionWarning ? (
              <Text style={{ color: "#777" }}>
                All sales are final, refunds will not be issued. Cancellation of
                a subscription will result in your account being downgraded to a
                free account 30 days after your last payment
              </Text>
            ) : null}
          </div>
          <div className={styles.buttonsContainer}>
            {loading ? <Loading /> : null}
            <Button variant="outlineTertiary" shape="square" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              shape="square"
              disabled={loading || !creditCard || !agreeCheck || isCurrentPlan || !termsAndConditionsCheck}
              onClick={() => onConfirm(coupon)}
            >
              Confirm & Pay
            </Button>
          </div>

          <Modal
            visible={showPaymentMethodsModal}
            onClose={() => setShowPaymentMethodsModal(false)}
          >
            <PaymentMethods />
          </Modal>
        </div>
      ) : (
        <div className={styles.errorMessageContainer}>
          {isCurrentPlan && !hasCoaches ? (
            <Text style={{ fontSize: "16px" }}>
              You already have the {previousPlan.label} Plan!
            </Text>
          ) : hasCoaches ? (
            <Text style={{ fontSize: "16px" }}>
              You cannot change plans when you have the Coaches Plan!
            </Text>
          ) : gettingCoachesPlan && previousPlan && !hasCoaches ? (
            <Text style={{ fontSize: "16px" }}>
              To purchase the Coaches plan, you must cancel your{" "}
              {previousPlan.label} Plan first.
            </Text>
          ) : (
            <>
              <Text style={{ fontSize: "16px" }}>
                Something went wrong trying to get that plan. <br /> Please send
                a report and tell us how you got here:
              </Text>
              <a
                style={{ color: "red", textDecoration: "underline" }}
                href={emailLink}
              >
                Send Report
              </a>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(CheckoutModal);
