import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_ROUTES } from "../../../../common/route";
import { getUserReviews } from "../../../../common/services/firebase/reviews";
import Button from "../../../components/button";
import Text from "../../../components/text";
import ReviewListItem from "../review-list-item";
import Filters from "./filters";
import styles from "./styles.module.css";

const AdminReview = () => {
  const [reviews, setReviews] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const filteredReviews = useMemo(() => {
    if (!reviews?.length) return [];
    const filtersKeys = Object.keys(filters);
    if (!filtersKeys.length) return reviews;
    return reviews.filter((r) => {
      return filtersKeys.every((filterKey) => {
        const filterValue = filters[filterKey];
        return filterValue && r[filterKey] === filterValue;
      });
    });
  }, [reviews, filters]);

  const onChangeFilters = (key, value) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (value === undefined) delete newFilters[key];
      else newFilters[key] = value;
      return newFilters;
    });
  };

  useEffect(() => {
    setLoading(true);
    getUserReviews({})
      .then(setReviews)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Text className={styles.title}>Reviews</Text>
      </div>
      {loading ? <Text>Loading...</Text> : null}
      <div className={styles.manageReviewsContainer}>
        <Filters filters={filters} onChange={onChangeFilters} />
        <Button
          variant="primary"
          onClick={() => history.push(ADMIN_ROUTES.reviewsStructure)}
        >
          Manage reviews structure
        </Button>
      </div>
      {!filteredReviews.length ? <Text>No reviews found</Text> : null}
      {filteredReviews
        .sort((a, b) => b._createdAt - a._createdAt)
        .map((review) => {
          return <ReviewListItem key={review.id} {...review} />;
        })}
    </div>
  );
};

export default React.memo(AdminReview);
